import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import "../../component/css/delivery_agents.css";
import { IoMdMore } from "react-icons/io";
import { useNavigate, Link } from "react-router-dom";
import aang from "../../component/images/aang.jpg";
import No_Agents from "../../component/notfound/No_Agents";
import { FaSearch } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { ClipLoader } from "react-spinners";
import PageCounter from "../../component/page_counter";
import {
  ClassNameDeterminer,
  NameTrimer,
} from "../../component/content_trimer";
import SecheduleToggle from "./component/SecheduleToggle";

export const ZeroChecker = (cash) => {
  if (cash === 0) {
    return "₦0.00";
  } else {
    return "₦" + cash.toLocaleString();
  }
};

export default function Delivery_Agents() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const [result, setResult] = useState(null);
  const [searchString, setSearchString] = useState("");
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  const [pageCount, setPageCount] = React.useState(1);
  const [disable, setDisable] = useState(false);
  const [disable1, setDisable1] = useState(false);
  const [search, setSearch] = useState(false);
  const [resultPerPage, setResultPerPage] = useState(20);
  const [toggleStates, setToggleStates] = useState({});


  // const [delivery_agent_id, setDevliveryAgentId] = useState('');

  useEffect(() => {

    const savedPage = parseInt(localStorage.getItem('agentLastPage')) || 1;
    setPageCount(savedPage)
    fetchData(savedPage);
  }, [isLoaded === false]);

  const fetchAgentSchedules = async () => {
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_search/get_delivery_agents_schedule",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ token }),
        }
      );
  
      const result = await response.json();
  
      if (response.status === 200 && result.status === "success") {
        console.log("Fetched agents:", result.agents);
  
        // Create a mapping of agent ID to toggle state
        const newToggleStates = {};
        result.agents.forEach(agent => {
          newToggleStates[agent._id] = agent.toggle_state_schedule_delivery;
        });
  
        setToggleStates(newToggleStates); // Update state with latest values
      }
    } catch (error) {
      console.error("Error fetching agent schedules:", error);
    }
  };
  
  
  // useEffect to poll data every 10 minutes
  useEffect(() => {
    fetchAgentSchedules(); // Fetch immediately on mount
    const interval = setInterval(fetchAgentSchedules, 10000 * 60);
    return () => clearInterval(interval);
  }, []);
  
  


  const fetchData = async (pageCountM = pageCount) => {
    try {
      setSearch(false);
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/view_ops/delivery_agents",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            pageCount: pageCountM,
            resultPerPage: resultPerPage,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // getData(await response.json());
      const resultM = await response.json();
      console.log(resultM);
      setResult(resultM);
      setIsLoaded(true);
      console.log(resultM.delivery_agents)
      setData(resultM.delivery_agents);
      //console.log(data);
      //console.log('got here')

      if (response.status === 200) {
        setDisable(false);
        setDisable1(false);
        //console.log('data gotten succesfully');
      }
    } catch (error) {
      //console.log(error);
    }
  };

  //schedule function to fix an agent to be schedule and not to be un-schedule
  const toggleSchedule = async (agentId, currentStatus) => {
    const newStatus = !currentStatus;
  
    // Optimistically update UI
    setData(prevAgents =>
      prevAgents.map(agent =>
        agent._id === agentId
          ? { ...agent, toggle_state_schedule_delivery: newStatus }
          : agent
      )
    );
  
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_search/toggle_delivery_agent",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            token,
            agentId,
            toggleState: newStatus,
          }),
        }
      );
  
      const result = await response.json();
  
      if (response.status !== 200) {
        console.error("Error updating agent:", result.msg);
        alert("Failed to update schedule status. Please try again.");
  
        // Revert state if update fails
        setData(prevAgents =>
          prevAgents.map(agent =>
            agent._id === agentId
              ? { ...agent, toggle_state_schedule_delivery: currentStatus }
              : agent
          )
        );
      } else {
        console.log("Agent updated successfully:", result);
  
        // Fetch updated list from the server to sync changes
        setTimeout(() => {
          fetchAgentSchedules();
        }, 500); // Delay fetching to ensure backend update is complete
      }
  
    } catch (error) {
      console.error("Request failed", error);
      alert("Network error. Please check your connection.");
  
      // Revert state if error occurs
      setData(prevAgents =>
        prevAgents.map(agent =>
          agent._id === agentId
            ? { ...agent, toggle_state_schedule_delivery: currentStatus }
            : agent
        )
      );
    }
  };
  
  




  // function Agent(props) {
  //   const [popUpMenu, setPopUpMenu] = React.useState(false);
  //   //console.log(props)
  //   const details = props.name;
  //   // setDevliveryAgentId(id);
  //   return (
  //     <div className="agent-App">
  //       <button onClick={() => setPopUpMenu(!popUpMenu)} className='more-details'>
  //         <IoMdMore />
  //       </button>
  //       {popUpMenu && (
  //         <ClickAwayListener onClickAway={() => setPopUpMenu(false)}>
  //           {PopUpMenu(details)}
  //         </ClickAwayListener>
  //       )}
  //     </div>
  //   );
  // }

  const minusPagec = () => {
    if (pageCount <= 1) {
      return;
    } else {
      setDisable(true);
      const pageCountM = pageCount - 1;
      setPageCount(pageCountM);
      localStorage.setItem('agentLastPage', pageCountM)
      if (search) {
        fetchSearchData(pageCountM);
      } else {
        fetchData(pageCountM);
      }
    }
  };
  const addPagec = () => {
    if (data?.length <= resultPerPage - 1) return data;
    setDisable1(true);
    const pageCountM = pageCount + 1;
    setPageCount(pageCountM);
    localStorage.setItem('agentLastPage', pageCountM)
    if (search) {
      fetchSearchData(pageCountM);
    } else {
      fetchData(pageCountM);
    }
  };
  const DataChecker = (data) => {
    if (data) {
      return data;
    } else {
      return "No Data Yet";
    }
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    setIsLoaded(false);
    setPageCount(1);
  };

  const Menu = (delivery_agent_details) => (
    <div className="admin-App ">
      <Popup
        trigger={
          <button className="more-details">
            {" "}
            <IoMdMore />{" "}
          </button>
        }
        position="right top"
        on="click"
        closeOnDocumentClick
        mouseLeaveDelay={300}
        mouseEnterDelay={0}
      // contentStyle={{ padding: '0px', border: 'none' }}
      // arrow={false}
      >
        <ul className="drop-down3 -top-10">
          <li
            onClick={() =>
              navigate("/App/Individual_Delivery_Agent", {
                state: {
                  id: delivery_agent_details.id,
                  fleet_manager_code: delivery_agent_details.fleet_manager_code,
                },
              })
            }
          >
            View Agent's profile
          </li>
          <li
            onClick={() =>
              navigate("/App/Message_Delivery_Agent", {
                state: { details: delivery_agent_details },
              })
            }
          >
            Message Agent's
          </li>
          <li
            onClick={() =>
              navigate("/App/Block_Delivery_Agent", {
                state: { details: delivery_agent_details },
              })
            }
          >
            Block Agent's account
          </li>
          <li
            onClick={() => {
              console.log(delivery_agent_details);

              navigate("/App/Delete_Delivery_Agent", {
                state: { details: delivery_agent_details },
              })
            }
            }
          >
            Delete Agent's account
          </li>
          <li
            onClick={() => {
              console.log(delivery_agent_details);
              navigate("/App/Agent_Pending_Order_Timeout", {
                state: { details: delivery_agent_details },
              })
            }
            }
          >
            Timeout Pending Orders
          </li>
        </ul>
      </Popup>
    </div>
  );

  // function PopUpMenu(props) {
  //   //console.log(props)
  //   const delivery_agent_details= props;
  //   //console.log(delivery_agent_details.id);
  //   return (
  //     <ul className="drop-down">
  //       <li onClick={() => navigate('/App/Individual_Delivery_Agent', {state:{id: delivery_agent_details.id, fleet_manager_code: delivery_agent_details.fleet_manager_code}})}>View Agent's profile</li>
  //         <li onClick={() => navigate('/App/Message_Delivery_Agent', {state:{details: delivery_agent_details}})}>Message Agent's</li>
  //         <li onClick={() => navigate('/App/Block_Delivery_Agent', {state:{details: delivery_agent_details}})}>Block Agent's account</li>
  //         <li onClick={() => navigate('/App/Delete_Delivery_Agent', {state:{details: delivery_agent_details}})}>Delete Agent's account</li>
  //     </ul>
  //   );
  // }

  const fetchSearchData = async (pageCountM) => {
    try {
      //console.log(searchString);
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_search/delivery_agents",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            pageCount: pageCountM || pageCount,
            search: searchString,
            resultPerPage: resultPerPage,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );

      // console.log('got here');
      // setData(await response.json());
      const result = await response.json();
      setData(result.delivery_agents);
      console.log(result.delivery_agents);
      console.log(searchString);
      console.log(result);
      setResult(result);

      if (result.msg === "Success") {
        console.log('data gotten succesfully');
        setDisable(false);
        setDisable1(false);
      } else {
        setData("");
        //console.log('some error occurred');
      }
    } catch (error) {
      //console.log(error);
    }
  };

  if (!isLoaded) {
    //console.log('got here')
    return (
      <h1 className="loading-pages">
        <ClipLoader color={"#1FAA08"} size={100} />
      </h1>
    );
  } else if (data?.length > 0) {
    //console.log('data gotten succesfully');
    return (
      <div className="delivery-agents">
        <div className="agent-properties">
          <h1 className="agent-title">Delivery Agents</h1>
          <div className="agents-table">
            <div className="users-search-box-container">
              <div className="main-search-box-container">
                <input
                  type="text"
                  placeholder="Search Delivery Agent"
                  className="search-box"
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                />
                <button className="delivery-searching">
                  <FaSearch
                    onClick={() => {
                      fetchSearchData();
                      setSearch(true);
                      setPageCount(1);
                    }}
                  />
                </button>
              </div>
              <div>
                <Link to="/App/Blocked_Agents">
                  {" "}
                  <button className="delivery-agent-block">
                    View blocked List
                  </button>
                </Link>
              </div>
            </div>

            <div className="result-per-page">
              <form>
                Results per page
                <input
                  className="chizys-input"
                  type="number"
                  value={resultPerPage}
                  onChange={(e) => setResultPerPage(e.target.value)}
                />
                <button className="chizys-button" onClick={handleButtonClick}>
                  Done
                </button>
              </form>
            </div>
            <table className="agent-table relative">
              <th>
                <div className="agent-table-left">Picture</div>
              </th>
              <th>Agents ID</th>
              <th>Full name</th>
              <th>Email address</th>
              <th>Scheduled Agents</th>
              <th>Phone number</th>
              <th>Total Earnings</th>
              <tbody>
                {data?.map((item, i) => (
                  <tr key={i}>

                    <td>

                      <div className="users-table-pic">
                        <img
                          src={item?.img_url ? item?.img_url : aang}
                          alt=""
                          className="users-table-pic"
                          style={{ objectFit: "cover" }}
                        />
                      </div>{" "}
                    </td>
                    <td>{item?.delivery_agent_code}</td>
                    <td className={ClassNameDeterminer(item?.fullname)}>
                      {NameTrimer(DataChecker(item?.fullname))}
                      <p style={{ display: "none" }}>
                        {DataChecker(item?.fullname)}
                      </p>
                    </td>
                    <td>{item?.email}</td>
                    <td className="">

                      <SecheduleToggle

                        agentId={item._id}
                        currentState={toggleStates[item._id] ?? false} // Use updated state
                        onToggle={toggleSchedule}
                      />
                    </td>
                    <td>{item?.phone_no}</td>
                    <td>{ZeroChecker(item?.total_earnings)}</td>
                    <td>
                      <div className="popup-click">
                        {Menu({
                          id: item?._id,
                          name: item?.fullname,
                          code: item?.delivery_agent_code,
                          fleet_manager_code: item?.fleet_manager_code,
                          img: item?.img_url,
                          email: item?.email,
                        })}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            style={{
              height: "20px",
              display: "flex",
              alignContent: "center",
              justifyContent: "flex-end",
              paddingRight: "20px",
              marginTop: "10px",
            }}
          >
            <div>
              {disable ? (
                <ClipLoader color={"black"} loading={disable} size={15} />
              ) : (
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  className={pageCount <= 1 ? "icon-space-less" : "icon-space"}
                  onClick={minusPagec}
                />
              )}
            </div>
            <h6>{PageCounter(result.count, pageCount, resultPerPage)}</h6>
            <div>
              {disable1 ? (
                <ClipLoader color={"black"} loading={disable1} size={15} />
              ) : (
                <FontAwesomeIcon
                  icon={faAngleRight}
                  className={
                    data?.length <= resultPerPage - 1
                      ? "icon-space-less"
                      : "icon-space"
                  }
                  onClick={addPagec}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (data?.length === 0) {
    //console.log('and here');
    return <No_Agents />;
  }
}
