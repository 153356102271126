import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import '../css/sidebar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MdOutlineNoteAlt } from 'react-icons/md';
import { FaRegEnvelope, FaAnchor, FaMap } from 'react-icons/fa';
import { BiUser } from 'react-icons/bi';
import { MdFeedback } from 'react-icons/md';
import { faTruck, faUserCog, faCreditCard, faPersonBiking, faSignOut, faSackDollar, faSliders, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { TokenContext } from '../../component/token_context';
import red from '../../component/images/red.png';

export default function Sidebar() {
  const values = useContext(TokenContext);
  const { new_msg, setNewMsg, new_reg_app, setNewRegApp, new_dev_req, SetNewDevReq, new_rep, setNewRep, listen, setListen } = values;

  const sidebarData = [
    {
      title: 'DASHBOARD',
      items: [
        {
          to: '/App/Dashboard',
          icon: <FaAnchor className='icons-space' />,
          label: 'Dashboard',
          newFlag: new_dev_req,
        },
      ],
    },
    {
      title: "Mapping",
      items: [
        {
          to: '/App/Map',
          icon: <FaMap className='icons-space' />,
          label: 'Map'
        }
      ]
    },
    {
      title: 'MEMBERS',
      items: [
        {
          to: '/App/Users',
          icon: <BiUser className='icons-space2' />,
          label: 'Users',
        },
        {
          to: '/App/Delivery_Agents',
          icon: <FontAwesomeIcon icon={faPersonBiking} className='icons-space' />,
          label: 'Delivery Agents',
        },
        {
          to: '/App/Fleet_Managers',
          icon: <FontAwesomeIcon icon={faTruck} className='icons-space' />,
          label: 'Fleet Managers',
        },
        {
          to: '/App/Administrator',
          icon: <FontAwesomeIcon icon={faUserCog} className='icons-space' />,
          label: 'Administrators',
        },
      ],
    },
    {
      title: 'FINANCIALS',
      items: [
        {
          to: '/App/Payment_Record/Agent_Payment_Record',
          icon: <FontAwesomeIcon icon={faCreditCard} className='icons-space' />,
          label: 'Payment History',
        },
        {
          to: '/App/Revenue',
          icon: <FontAwesomeIcon icon={faSackDollar} className='icons-space' />,
          label: 'Revenue',
        },
      ],
    },
    {
      title: 'ACCOUNT',
      items: [
        {
          to: '/App/Agent_Applications',
          icon: <MdOutlineNoteAlt className='icons-space3' />,
          label: 'Agent Applications',
          newFlag: new_reg_app,
        },
        {
          to: '/App/Reports',
          icon: <MdFeedback className='icons-space3' />,
          label: 'Reports',
          newFlag: new_rep,
        },
        {
          to: '/App/System_Message',
          icon: <FaRegEnvelope className='icons-space' />,
          label: 'System message',
        },
        {
          to: '/App/referrals',
          icon: <FontAwesomeIcon icon={faPaperPlane} className='icons-space' />,
          label: 'Referrals',
        },
      ],
    },
    {
      title: 'HELP',
      items: [
        {
          to: '/App/Support',
          icon: <MdFeedback className='icons-space3' />,
          label: 'Help & Feedback',
          newFlag: new_msg,
        },
        {
          to: '/App/Settings',
          icon: <FontAwesomeIcon icon={faSliders} className='icons-space' />,
          label: 'Settings',
        },
        {
          to: '/App/Logout',
          icon: <FontAwesomeIcon icon={faSignOut} className='icons-space' />,
          label: 'Log Out',
        },
      ],
    },
  ];

  return (
    <nav >
      <div className='sidebar w-full'>
        <input type="checkbox" name="" id="check" />
        <div className='hamburger-menu-container'>
          <div className='hamburger-menu'>
            <div></div>
          </div>
        </div>
        <div className=' py-5 pb-10 sidebarWrapper flex justify-start items-start flex-col w-full'>
          {sidebarData.map((menu, index) => (
            <div key={index} className='sidebarMenu w-full flex flex-col mb-4 items-start justify-start'>
              <h3 className='sidebarTitle self-start'>{menu.title}</h3>
              <ul className='sidebarList items-start flex flex-col justify-start self-start'>
                {menu.items.map((item, i) => (
                  <NavLink
                    key={i}
                    to={item.to}
                    className='sidebarListItem flex flex-col justify-start w-full items-start'
                    style={({ isActive }) => ({
                      backgroundColor: isActive ? 'rgba(26, 168, 3, 0.15)' : '',
                    })}
                  >
                    {item.newFlag && (
                      <img
                        className={item.newFlag ? 'new-dev-req' : ''}
                        src={red}
                        alt='red'
                        style={{ height: '10px', width: '10px', display: 'none' }}
                      />
                    )}
                    {item.icon}
                    {item.label}
                  </NavLink>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </nav>
  );
}
