import React from "react";

const ToggleSwitchSche = ({ isOn, onToggle }) => {
  return (
    <div
      className={`w-16 h-8 flex items-center rounded-full p-1 cursor-pointer transition-all duration-300 ${
        isOn ? "bg-blue-500" : "bg-gray-500"
      }`}
      onClick={() => onToggle(!isOn)}
    >
      <div
        className={`w-6 h-6 bg-white rounded-full shadow-md transform transition-transform ${
          isOn ? "translate-x-8" : "translate-x-0"
        }`}
      ></div>
    </div>
  );
};

export default ToggleSwitchSche;
