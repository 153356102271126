import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Dashboard from "./pages/dashboard/Dashboard";
import Users from "./pages/users/Users";
import Delivery_Agents from "./pages/delivery_agents/Delivery_Agents";
import Fleet_Managers from "./pages/fleet_managers/Fleet_Managers";
import Administrator from "./pages/administrator/Administrator";
import Agent_Applications from "./pages/agent_applications/Agent_Applications";
import Payment_record from "./pages/payment_record/Payment_record";
import All_Deliveries from "./pages/dashboard/All_Deliveries";
import Cancelled_Delivery from "./pages/dashboard/Cancelled_Delivery";
import Agent_Cancelled_Deliveries from "./pages/dashboard/Agent_Cancelled_Deliveries";
import User_Cancelled_Deliveries from "./pages/dashboard/User_Cancelled_Deliveries";
import System_Message from "./pages/system_message/System_Message";
import Support from "./pages/support/Support";
import Reports from "./pages/reports/Reports";
import Revenue from "./pages/revenue/Revenue";
import Add_Admin from "./pages/administrator/Add_Admin";
import { ProfilePix } from "./pages/support/ProfilePix";
import Unauthorized from "./pages/Unauthorized";
import SessionExpired from "./pages/SessionExpired";
import Login from "./pages/logout/Login";
import Agent_Form from "./pages/agent_applications/Agent_Form";
import { UseTokenProvider } from "./component/token_context";
import Logout from "./pages/logout/Logout";
import User_Report from "./pages/reports/User_Report";
import Agent_Report from "./pages/reports/Agent_Report";
import Individual_user from "./pages/users/Individual_user";
import Individual_Delivery_Agent from "./pages/delivery_agents/Individual_Delivery_Agent";
import Individual_Fleet from "./pages/fleet_managers/Individual_Fleet";
import Scheduled_Delivery from "./pages/dashboard/Scheduled_Delivery";
import Successfully_Cancelled from "./pages/dashboard/Successfully_Cancelled";
import Agent_Support from "./pages/support/Agent_Support";
import User_Support from "./pages/support/User_Support";
import Block_User from "./component/popup/Block_User";
import Delete_User from "./component/popup/Delete_User";
import Block_Administrator from "./component/popup/Block_Administrator";
import Block_Delivery_Agent from "./component/popup/Block_Delivery_Agent";
import Delete_Fleet_manager from "./component/popup/Delete_Fleet_manager";
import Accept_Adminstrator_Application from "./component/popup/Accept_Agent_Application";
import Accept_Agent_Application from "./component/popup/Accept_Agent_Application";
import Block_Fleet_Manager from "./component/popup/Block_Fleet_Manager";
import Decline_Agent_Application from "./component/popup/Decline_Agent_Application";
import Delete_Administrator from "./component/popup/Delete_Administrator";
import Disable_Administrator from "./component/popup/Disable_Administrator";
import Delete_Delivery_Agent from "./component/popup/Delete_Delivery_Agent";
import Timeout_Delivery from "./component/popup/Timeout_Delivery";
import No_Users from "./component/notfound/No_Users";
import No_Agents from "./component/notfound/No_Agents";
import No_Agent_Application from "./component/notfound/No_Agent_Application";
import No_Fleet from "./component/notfound/No_Fleet";
import No_Blocked_Fleet from "./component/no_blocked/No_Blocked_Fleet";
import No_Blocked_Users from "./component/no_blocked/No_Blocked_Users";
import No_Blocked_Agent from "./component/no_blocked/No_Blocked_Agent";
import No_Blocked_Admin from "./component/no_blocked/No_Blocked_Admin";
import No_All_Deliveries from "./component/notfound/No_All_Deliveries";
import Fleet_Manager_Payment from "./pages/payment_record/Fleet_Manager_Payment";
import Blocked_Admins from "./component/blocked/Blocked_Admins";
import Blocked_Fleet from "./component/blocked/Blocked_Fleet";
import Blocked_Agents from "./component/blocked/Blocked_Agents";
import Blocked_Users from "./component/blocked/Blocked_Users";
import Change_Admin_Role from "./component/profile/Change_Admin_Role";
import Change_Phone_Number from "./component/profile/Change_Phone_Number";
import Changed_Admin_Role_Successfully from "./component/profile/Changed_Admin_Role_Successfully";
import Otp from "./component/profile/Otp";
import Profile from "./component/profile/Profile";
import Changed_Phone_Number_Successfully from "./component/profile/Changed_Phone_Number_Successfully";
import Changed_Fullname_Successfully from "./component/profile/Changed_Fullname_Successfully";
import Individual_Agent_Payment from "./pages/delivery_agents/Individual_Agent_Payment";
import Individual_Fleet_Payment from "./pages/fleet_managers/Individual_Fleet_Payment";
import Settings from "./pages/settings/Settings";
import VideoSettings from "./pages/settings/VideoSettings";
import Unblock_Delivery_Agent from "./component/popup/Unblock_Delivery_Agent";
import Unblock_Fleet_Manager from "./component/popup/Unblock_Fleet_Manager";
import Unblock_User from "./component/popup/Unblock_User";
import Unblock_Admin from "./component/popup/Unblock_Admin";
import Change_Name from "./component/profile/Change_Name";
import firebase from "./utils/firebase";
import No_Admin from "./component/notfound/No_Admin";
import No_Payment from "./component/notfound/No_Payment";
import No_Report from "./component/notfound/No_Report";
import Instant_Deliveries from "./pages/dashboard/Instant_Deliveries";
import Scheduled_Deliveries from "./pages/dashboard/Scheduled_Deliveries";
import Cancelled_Deliveries from "./pages/dashboard/Cancelled_Deliveries";
import Delivery_Details from "./pages/logout/Delivery_Details";
import Instant_Delivery from "./pages/dashboard/Instant_Delivery";
import No_instant_Deliveries from "./component/notfound/No_instant_Deliveries";
import No_Cancelled_Deliveries from "./component/notfound/No_Cancelled_Deliveries";
import No_Scheduled_Deliveries from "./component/notfound/No_Scheduled_Deliveries";
import Assign_Delivery from "./pages/assign_delivery/Assign_Delivery";
import Pickload_Delivery from "./pages/assign_delivery/Pickload_Delivery";
import General_Delivery from "./pages/assign_delivery/General_Delivery";
import User_Reporttoggle from "./pages/reports/User_Reporttoggle";
import User_Resolved from "./pages/reports/User_Resolved";
import Agent_Resolved from "./pages/reports/Agent_Resolved";
import Agent_Reporttoggle from "./pages/reports/Agent_Reporttoggle";
import No_AgentReport from "./component/notfound/No_AgentReport";
import Assign_Agent from "./component/popup/Assign_Agent";
import Message_User from "./pages/single_message/Message_User";
import Message_Delivery_Agent from "./pages/single_message/Message_Delivery_Agent";
import Message_Fleet_Manager from "./pages/single_message/Message_Fleet_Manager";
import ProtectedRoutes from "./ProtectedRoutes";
import Reverse_Payment from "./component/popup/Reverse_Payment";
import Confirm_Pin from "./pages/Confirm_Pin";
import Confirm_Pin2 from "./pages/Confirm_PIn2";
import Confirm_Pin3 from "./pages/Confirm_Pin3";
import Referral from "./pages/referral/Referral";
import UserReferral from "./pages/referral/UserReferral";
import AgentReferral from "./pages/referral/AgentReferral";
import RewardedReferrals from "./pages/referral/RewardedReferrals";
import IndividualUserReferral from "./pages/referral/IndividualUserReferral";
import IndividualAgentReferral from "./pages/referral/IndividualAgentReferral";
import Dashboard_2 from "./pages/dashboard/Dashboard_2";
import AgentReferralTarget from "./pages/referral/AgentReferralTarget";
import Agent_Payment_Record from "./pages/payment_record/Agent_Payment_Record";
import Max_TImeout_Delivery from "./component/popup/Max_TImeout_Delivery";
import Mapping from "./pages/Mapping/Mapping";
import { LoadScript } from "@react-google-maps/api";

const allowedRoles = {
  master: "master",
  finance: "Finance Manager",
  operation: "Operation Manager",
  users_care: "Users Customer care",
  marketing: "Marketing and branding team",
  agents_support: "Agents Support",
};

const AllowedRoles = [
  "master",
  "Finance Manager",
  "Operation Manager",
  "Users Customer care",
  "Marketing and branding team",
  "Agents Support",
];

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <UseTokenProvider>
      <BrowserRouter>
        {/* <LoadScript googleMapsApiKey="AIzaSyBoWy2-vzkQzOw9FrCKUyfxWpa5_dsPi10"> */}

          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="Session_Expired" element={<SessionExpired />} />
            <Route
              path="App"
              element={
                <ProtectedRoutes allowedRoles={AllowedRoles}>
                  <App />
                </ProtectedRoutes>
              }
            >
              <Route path="Dashboard" element={<Dashboard />} />
              <Route
                path="Users"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.users_care]}
                  >
                    <Users />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Delivery_Agents"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                      allowedRoles.operation,
                    ]}
                  >
                    <Delivery_Agents />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Fleet_Managers"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                      allowedRoles.operation,
                    ]}
                  >
                    <Fleet_Managers />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Administrator"
                element={
                  <ProtectedRoutes allowedRoles={[allowedRoles.master]}>
                    <Administrator />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Agent_Applications"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.operation]}
                  >
                    <Agent_Applications />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Payment_Record"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.finance]}
                  >
                    <Payment_record />
                  </ProtectedRoutes>
                }
              >
                <Route path="Agent_Payment_Record" element={<Agent_Payment_Record />} />
                <Route path="Fleet_Manager" element={<Fleet_Manager_Payment />} />
              </Route>

              <Route
                path="Reverse_Payment"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.finance]}
                  >
                    <Reverse_Payment />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Confirm_Pin"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.finance]}
                  >
                    <Confirm_Pin />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Confirm_Pin2"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.finance]}
                  >
                    <Confirm_Pin2 />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Confirm_Pin3"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.operation]}
                  >
                    <Confirm_Pin3 />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="System_Message"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.marketing]}
                  >
                    <System_Message />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="All_Deliveries"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.operation]}
                  >
                    <All_Deliveries />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                Path="Cancelled_Delivery"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.operation]}
                  >
                    <Cancelled_Delivery />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Agent_Cancelled_Deliveries"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.operation]}
                  >
                    <Agent_Cancelled_Deliveries />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="User_Cancelled_Deliveries"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.operation]}
                  >
                    <User_Cancelled_Deliveries />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Support"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                      allowedRoles.users_care,
                    ]}
                  >
                    <Support />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Reports"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                      allowedRoles.users_care,
                    ]}
                  >
                    <Reports />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Revenue"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.finance]}
                  >
                    <Revenue />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route path="ProfilePix" element={<ProfilePix />} />
              <Route
                path="Add_Admin"
                element={
                  <ProtectedRoutes allowedRoles={[allowedRoles.master]}>
                    <Add_Admin />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Agent_Form"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.operation]}
                  >
                    <Agent_Form />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route path="Logout" element={<Logout />} />
              <Route
                path="User_Report"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.users_care]}
                  >
                    <User_Report />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Agent_Report"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                    ]}
                  >
                    <Agent_Report />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Individual_user"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.users_care]}
                  >
                    <Individual_user />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Individual_Delivery_Agent"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                    ]}
                  >
                    <Individual_Delivery_Agent />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Individual_Fleet"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                    ]}
                  >
                    <Individual_Fleet />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Cancelled_Delivery"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.operation]}
                  >
                    <Cancelled_Delivery />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Scheduled_Delivery"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.operation]}
                  >
                    <Scheduled_Delivery />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Agent_Support"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                    ]}
                  >
                    <Agent_Support />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="User_Support"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.users_care]}
                  >
                    <User_Support />
                  </ProtectedRoutes>
                }
              ></Route>
              {/* <Route
              path="Successfully_Cancelled"
              element={
                <ProtectedRoutes
                  allowedRoles={[allowedRoles.master, allowedRoles.operation]}
                >
                  <Successfully_Cancelled />
                </ProtectedRoutes>
              }
            ></Route> */}
              <Route
                path="Timeout_Delivery"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.operation]}
                  >
                    <Timeout_Delivery />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Agent_Pending_Order_Timeout"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.operation]}
                  >
                    < Max_TImeout_Delivery />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Block_User"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.users_care]}
                  >
                    <Block_User />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Delete_User"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.users_care]}
                  >
                    <Delete_User />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Block_Administrator"
                element={
                  <ProtectedRoutes allowedRoles={[allowedRoles.master]}>
                    <Block_Administrator />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Block_Delivery_Agent"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                    ]}
                  >
                    <Block_Delivery_Agent />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Delete_Fleet_manager"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                    ]}
                  >
                    <Delete_Fleet_manager />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Accept_Adminstrator_Application"
                element={
                  <ProtectedRoutes allowedRoles={[allowedRoles.master]}>
                    <Accept_Adminstrator_Application />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Accept_Agent_Application"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.operation]}
                  >
                    <Accept_Agent_Application />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Block_Fleet_Manager"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                    ]}
                  >
                    <Block_Fleet_Manager />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Decline_Agent_Application"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.operation]}
                  >
                    <Decline_Agent_Application />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Delete_Administrator"
                element={
                  <ProtectedRoutes allowedRoles={[allowedRoles.master]}>
                    <Delete_Administrator />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Disable_Administrator"
                element={
                  <ProtectedRoutes allowedRoles={[allowedRoles.master]}>
                    <Disable_Administrator />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Delete_Delivery_Agent"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                    ]}
                  >
                    <Delete_Delivery_Agent />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="No_Admin"
                element={
                  <ProtectedRoutes allowedRoles={[allowedRoles.master]}>
                    <No_Admin />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="No_Users"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.users_care]}
                  >
                    <No_Users />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="No_Agents"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                    ]}
                  >
                    <No_Agents />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="No_Agent_Application"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.operation]}
                  >
                    <No_Agent_Application />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="No_Fleet"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                    ]}
                  >
                    <No_Fleet />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="No_Blocked_Fleet"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                    ]}
                  >
                    <No_Blocked_Fleet />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="No_Blocked_Users"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.users_care]}
                  >
                    <No_Blocked_Users />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="No_Blocked_Agent"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                    ]}
                  >
                    <No_Blocked_Agent />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="No_Blocked_Admin"
                element={
                  <ProtectedRoutes allowedRoles={[allowedRoles.master]}>
                    <No_Blocked_Admin />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="No_All_Deliveries"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.operation]}
                  >
                    <No_All_Deliveries />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Fleet_Manager_Payment"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.finance]}
                  >
                    <Fleet_Manager_Payment />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Blocked_Agents"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                    ]}
                  >
                    <Blocked_Agents />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Blocked_Admins"
                element={
                  <ProtectedRoutes allowedRoles={[allowedRoles.master]}>
                    <Blocked_Admins />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Blocked_Users"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.users_care]}
                  >
                    <Blocked_Users />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Blocked_Fleet"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                    ]}
                  >
                    <Blocked_Fleet />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Change_Phone_Number"
                element={<Change_Phone_Number />}
              />
              <Route path="Otp" element={<Otp />} />
              <Route path="Profile" element={<Profile />} />
              <Route
                path="Change_Admin_Role"
                element={
                  <ProtectedRoutes allowedRoles={[allowedRoles.master]}>
                    <Change_Admin_Role />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Changed_Admin_Role_Successfully"
                element={
                  <ProtectedRoutes allowedRoles={[allowedRoles.master]}>
                    <Changed_Admin_Role_Successfully />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Changed_Fullname_Successfully"
                element={<Changed_Fullname_Successfully />}
              />
              <Route
                path="Changed_Phone_Number_Successfully"
                element={<Changed_Phone_Number_Successfully />}
              />
              <Route
                path="Individual_Agent_Payment"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.finance]}
                  >
                    <Individual_Agent_Payment />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Individual_Fleet_Payment"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.finance]}
                  >
                    <Individual_Fleet_Payment />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Settings"
                element={
                  <ProtectedRoutes allowedRoles={[allowedRoles.master]}>
                    <Settings />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="VideoSettings"
                element={
                  <ProtectedRoutes allowedRoles={[allowedRoles.master]}>
                    <VideoSettings />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Unblock_Delivery_Agent"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                    ]}
                  >
                    <Unblock_Delivery_Agent />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Unblock_User"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.users_care]}
                  >
                    <Unblock_User />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Unblock_Fleet_Manager"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                    ]}
                  >
                    <Unblock_Fleet_Manager />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Unblock_Admin"
                element={
                  <ProtectedRoutes allowedRoles={[allowedRoles.master]}>
                    <Unblock_Admin />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route path="Change_Name" element={<Change_Name />} />
              <Route
                path="No_Payment"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.finance]}
                  >
                    <No_Payment />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="No_Report"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.users_care,
                      allowedRoles.agents_support,
                    ]}
                  >
                    <No_Report />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Instant_Deliveries"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.operation]}
                  >
                    <Instant_Deliveries />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Scheduled_Deliveries"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.operation]}
                  >
                    <Scheduled_Deliveries />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Cancelled_Deliveries"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.operation]}
                  >
                    <Cancelled_Deliveries />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Delivery_Details"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.operation]}
                  >
                    <Delivery_Details />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Instant_Delivery"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.operation]}
                  >
                    <Instant_Delivery />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="No_instant_Deliveries"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.operation]}
                  >
                    <No_instant_Deliveries />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="No_Cancelled_Deliveries"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.operation]}
                  >
                    <No_Cancelled_Deliveries />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="No_Scheduled_Deliveries"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.operation]}
                  >
                    <No_Scheduled_Deliveries />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Assign_Delivery"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.operation]}
                  >
                    <Assign_Delivery />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Assign_Agent"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.operation]}
                  >
                    <Assign_Agent />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Pickload_Delivery"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.operation]}
                  >
                    <Pickload_Delivery />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="General_Delivery"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.operation]}
                  >
                    <General_Delivery />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="User_Resolved"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.users_care]}
                  >
                    <User_Resolved />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="User_Reporttoggle"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.users_care]}
                  >
                    <User_Reporttoggle />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Agent_Resolved"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                    ]}
                  >
                    <Agent_Resolved />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Agent_Repporttogle"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                    ]}
                  >
                    <Agent_Reporttoggle />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="No_AgentReport"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                    ]}
                  >
                    <No_AgentReport />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Message_User"
                element={
                  <ProtectedRoutes
                    allowedRoles={[allowedRoles.master, allowedRoles.users_care]}
                  >
                    <Message_User />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route path="Unauthorized" element={<Unauthorized />} />
              <Route
                path="Message_Delivery_Agent"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                    ]}
                  >
                    <Message_Delivery_Agent />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="Message_Fleet_Manager"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                    ]}
                  >
                    <Message_Fleet_Manager />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="referrals"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                      allowedRoles.users_care,
                      allowedRoles.operation,
                    ]}
                  >
                    <Referral />
                  </ProtectedRoutes>
                }
              ></Route>
              {/* <Route
                path="Map"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                      allowedRoles.users_care,
                      allowedRoles.operation,
                    ]}
                  >
                    <Mapping />
                  </ProtectedRoutes>
                }
              /> */}
              <Route
                path="user_referral"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                      allowedRoles.users_care,
                      allowedRoles.operation,
                    ]}
                  >
                    <UserReferral />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="agent_referral"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                      allowedRoles.users_care,
                      allowedRoles.operation,
                    ]}
                  >
                    <AgentReferral />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="rewarded_referrals"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                      allowedRoles.users_care,
                      allowedRoles.operation,
                    ]}
                  >
                    <RewardedReferrals />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="individual_user_referrals"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                      allowedRoles.users_care,
                      allowedRoles.operation,
                    ]}
                  >
                    <IndividualUserReferral />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="individual_agent_referrals"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                      allowedRoles.users_care,
                      allowedRoles.operation,
                    ]}
                  >
                    <IndividualAgentReferral />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="admin_agent_referral"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                      allowedRoles.users_care,
                      allowedRoles.operation,
                    ]}
                  >
                    <IndividualAgentReferral />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="agent_daily_target"
                element={
                  <ProtectedRoutes
                    allowedRoles={[
                      allowedRoles.master,
                      allowedRoles.agents_support,
                      allowedRoles.users_care,
                      allowedRoles.operation,
                    ]}
                  >
                    <AgentReferralTarget />
                  </ProtectedRoutes>
                }
              ></Route>
            </Route>
          </Routes>
        {/* </LoadScript> */}
      </BrowserRouter>
    </UseTokenProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
