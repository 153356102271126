import React, { useEffect, useState } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { Outlet } from "react-router-dom";
import { db } from "../../utils/firebase";
import { collection, query, onSnapshot, where } from "firebase/firestore";
import { FaSearch } from "react-icons/fa";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

const Mapping = () => {
    const [agents, setAgents] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    const containerStyle = { width: "100%", height: "500px" };
    const center = { lat: 6.3350262, lng: 5.6256644 };
    const zoom = 20;

    // Sample Trip Data
    const tripSummary = [
        { name: "Completed", value: 40, color: "#4CAF50" },  // Green
        { name: "Ongoing", value: 25, color: "#2196F3" },    // Blue
        { name: "Canceled", value: 15, color: "#F44336" },   // Red
        { name: "Pending", value: 20, color: "#FF9800" }     // Orange
    ];

    useEffect(() => {
        const agentsRef = collection(db, 'delivery_agents');
        const q = query(agentsRef, where("is_online", "==", true));

        const unsubscribe = onSnapshot(
            q,
            (snapshot) => {
                const agentList = [];
                snapshot.forEach((doc) => {
                    const agentData = doc.data();
                    // console.log("Agent Data: ", agentData);

                    const lat = agentData.loca_lat;
                    const lng = agentData.loca_long;

                    if (typeof lat === 'number' && !isNaN(lat) && typeof lng === 'number' && !isNaN(lng)) {
                        agentList.push({
                            lat,
                            lng,
                            status: agentData.status,
                            img_url: agentData.img_url,
                            vehicle_type: agentData.vehicle_type,
                            fullname: agentData.fullname,
                            fullname: agentData.fullname,
                            phone: agentData.phone || " ",
                            email: agentData.email || " ",
                            location: agentData.location,
                            phone: agentData.phone,
                            status: agentData.status,
                        });
                    }
                });

                setAgents(agentList);
                setIsLoaded(true);
            },
            (err) => {
                console.error("Error fetching agent data: ", err);
                setError("Failed to load agent data");
                setIsLoaded(true);
            }
        );

        return () => unsubscribe();
    }, []);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="dashboard bg-white p-6">
            <div className="mb-6">
                <div className="border px-3 rounded-sm flex w-fit p-2 items-center">
                    <FaSearch />
                    <input type="search" placeholder="Search Agent" className="placeholder:text-[12px] outline-none mapping-placeo ml-4 border-none" />
                </div>
            </div>

            <div className="flex flex-col lg: gap-8 h-[60vh]">
                {/* Google Map */}
                <div className="w-full lg:w-[50%] h-full rounded-xl">
                    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={zoom}>
                        {isLoaded && agents.length > 0 ? (
                            agents.map((agent, index) => (
                                <Marker
                                    key={`agent-${index}`}
                                    icon={{
                                        url: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAwAB/XTZnx8AAAAASUVORK5CYII=", // 1x1 transparent image
                                        scaledSize: new window.google.maps.Size(1, 1),
                                    }}
                                    position={{ lat: agent.lat, lng: agent.lng }}
                                    label={{
                                        text: agent.vehicle_type === 'bike' ? '🚲' : '🚗',
                                        fontSize: "40px", // Adjust size
                                        color: "#000", // Optional: Change color
                                        fontWeight: "bolder", // Optional: Make it bold
                                    }} onClick={() => { console.log("Selected agent: ", agent); setSelectedAgent(agent) }}
                                />
                            ))
                        ) : (
                            <div>Loading agents...</div>
                        )}
                    </GoogleMap>
                    <Outlet />
                </div>

                {/* Agent Info & Trip Summary */}
                <div className="w-full lg:w-[50%] mt-10 flex flex-col items-center justify-between">
                    {/* Selected Agent Details */}
                    <div className="w-full h-fit flex flex-col lg:items-center shadow-2xl rounded-md border border-gray-300 p-3">
                        {selectedAgent ? (
                            <div className="flex justify p-4 gap-12">
                                <div>
                                    {console.log("=======", selectedAgent.img_url)}
                                    <img src={selectedAgent.img_url} alt={selectedAgent.fullname} className="w-24 rounded-lg" />
                                    <h3 className="text-[12px] mt-2 font-bold">{selectedAgent.fullname}</h3>
                                </div>
                                <div className="flex  text-[12px] font-bold">
                                    <div className="h-full w-[1px] mx-6 bg-black"></div>
                                    <div className="flex justify-center gap-1 flex-col">
                                        <p className="text-gray-600 font-bold"> Vehicle: {selectedAgent.vehicle_type.toUpperCase()}</p>
                                        <p className="text-gray-500"> Call :📞 {selectedAgent.phone} </p>
                                        <p className="text-gray-500"> Email :✉️ {selectedAgent.email || " "}</p>

                                        <p className="text-gray-500"> Location :✉️ {selectedAgent.location}</p>
                                        <p className="text-gray-500"> Phone Type :✉️ {selectedAgent.phone}</p>
                                        <p className={`${selectedAgent.status === "active" ? 'text-green-500' : 'text-red-500'} font-bold `}> Status :✉️ {selectedAgent.status}</p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <p className="text-gray-500">Click on a rider to see details</p>
                        )}
                    </div>

                    {/* Trip Summary Chart */}
                    <div className="w-full h-fit mt-10 mb-20 flex flex-col items-center shadow-2xl rounded-md border border-gray-300 p-3">
                        <h3 className="text-lg font-bold mb-8">Order Chart Summary</h3>
                        <PieChart width={300} height={250}>
                            <Pie
                                data={tripSummary}
                                dataKey="value"
                                cx="50%"
                                cy="50%"
                                outerRadius={80}
                                fill="#8884d8"
                                label
                            >
                                {tripSummary.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={entry.color} />
                                ))}
                            </Pie>
                            <Tooltip />
                            <Legend />
                        </PieChart>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Mapping;
