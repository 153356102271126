import React from 'react';

const SecheduleToggle = ({ agentId, currentState, onToggle }) => {
  // console.log(`Rendering Toggle for Agent ${agentId} with state:`, currentState);

  return (
    <div
      className={`w-12 h-6 flex items-center rounded-full p-1 cursor-pointer ${
        currentState ? "bg-green-500" : "bg-red-500"
      }`}
      onClick={() => onToggle(agentId, currentState)}
    >
      <div
        className={`w-4 h-4 bg-white rounded-full shadow-md transform transition-transform ${
          currentState ? "translate-x-6" : "translate-x-0"
        }`}
      ></div>
    </div>
  );
};


export default SecheduleToggle;
