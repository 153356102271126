import React, { useState, useEffect } from "react";
import "../../component/css/settings.css";
import bikes from "../../component/images/bikes.png";
import cars from "../../component/images/cars.png";
import trucks from "../../component/images/trucks.png";
import van from "../../component/images/van.png";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate, Link } from "react-router-dom";
import {
  updateRefundDays,
  updatePickupRadius,
  updateRefRate,
  updateBaseFare,
  updateKmRate,
  updateTimeRate,
  updateMinPrice,
  updatePickloadPercent,
  updateDeliveryWaitTime,
  updatePaymentTimeoutDuration,
  updatepercentageDiscount,
  fetchCurrentDiscount,
  fetchDiscountStatus,
  updateDiscountStatus,
} from "./settingsFunctions";
import ToggleSwitch from "./components/ToggleSwitch";
import ToggleSwtichSche from "./components/ToggleSwtichSche";
import Scheduled_setting from "./schedule_setting";

export default function Settings() {
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  // const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [result, setResult] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [ref_days, setRefDays] = useState("");
  const [url, setUrl] = useState("");
  const [radius, setRadius] = useState("");
  const [user_ref_percent, setUserRefPercent] = useState("");
  const [delivery_agent_ref_percent, setDeliveryAgentRefPercent] = useState("");
  const [bike_min_rate, setBikeMinRate] = useState("");
  const [car_min_rate, setCarMinRate] = useState("");
  const [van_min_rate, setVanMinRate] = useState("");
  const [truck_min_rate, setTruckMinRate] = useState("");
  const [bike_km_rate, setBikeKmRate] = useState("");
  const [car_km_rate, setCarKmRate] = useState("");
  const [van_km_rate, setVanKmRate] = useState("");
  const [truck_km_rate, setTruckKmRate] = useState("");
  const [bike_time_rate, setBikeTimeRate] = useState("");
  const [car_time_rate, setCarTimeRate] = useState("");
  const [van_time_rate, setVanTimeRate] = useState("");
  const [truck_time_rate, setTrucktimeRate] = useState("");
  const [bike_base_fare, setBikeBaseFare] = useState("");
  const [car_base_fare, setCarBaseFare] = useState("");
  const [van_base_fare, setVanBaseFare] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedSrc, setSelectedSrc] = useState("");
  const [truck_base_fare, setTruckBaseFare] = useState("");
  const [pickload_percent, setPickloadPercent] = useState("");
  const [delivery_wait_time, setDeliveryWaitTime] = useState("");
  const [payment_timeout_duration, setPaymentTimeOutDuration] = useState("");

  const [discount_percentage, setDeliveryPercentage] = useState("")
  // const [delivery_agent_percent, setDeliveryAgentPercent] = useState('');
  const [bike_text, setBikeText] = useState("Enabled");
  const [car_text, setCarText] = useState("Enabled");
  const [van_text, setVanText] = useState("Enabled");
  const [truck_text, setTruckText] = useState("Enabled");
  const [disable, setDisable] = useState(false);
  const [disable1, setDisable1] = useState(false);
  const [disable2, setDisable2] = useState(false);
  const [disable3, setDisable3] = useState(false);
  const [disable4, setDisable4] = useState(false);
  const [disable5, setDisable5] = useState(false);
  const [disable6, setDisable6] = useState(false);
  const [disable7, setDisable7] = useState(false);
  const [disable8, setDisable8] = useState(false);
  const [disable9, setDisable9] = useState(false);
  const [disable10, setDisable10] = useState(false);
  const [disable11, setDisable11] = useState(false);
  const [disable12, setDisable12] = useState(false);
  const [disable13, setDisable13] = useState(false);
  const [disable14, setDisable14] = useState(false);
  const [disable15, setDisable15] = useState(false);
  const [disable16, setDisable16] = useState(false);
  const [disable17, setDisable17] = useState(false);
  const [disable18, setDisable18] = useState(false);
  const [disable19, setDisable19] = useState(false);
  const [disable20, setDisable20] = useState(false);
  const [disable21, setDisable21] = useState(false);
  const [disable22, setDisable22] = useState(false);
  const [disableUrl, setDisableUrl] = useState(false);
  const [disable23, setDisable23] = useState(false);
  const [disable24, setDisable24] = useState(false);
  const [disable25, setDisable25] = useState(false);
  const [disable26, setDisable26] = useState(false);
  const [disable27, setDisable27] = useState(false);
  const [link, setLink] = useState("");
  const [disableUrlU, setDisableUrlU] = useState(false);
  const [urlU, setUrlU] = useState("");
  const [linkU, setLinkU] = useState("");
  const [disableUrlA, setDisableUrlA] = useState(false);
  const [urlA, setUrlA] = useState("");
  const [linkA, setLinkA] = useState("");

  const [currentDiscount, setCurrentDiscount] = useState(null);

  const [discountEnabled, setDiscountEnabled] = useState(null)
  const [discountPercentageRetrive, setDiscountPercentageRetrive] = useState('')

  const [isInstant, setIsInstant] = useState(true); // true = Instant Delivery, false = Scheduled


  const Converter = (amount) => {
    //console.log(amount)
    return "₦" + amount.toLocaleString();
  };

  const KmConverter = (amount) => {
    //console.log(amount)
    return amount.toLocaleString() + " meters";
  };

  const PercentageConverter = (num) => {
    //console.log(num);
    return num.toLocaleString() + "%";
  };

  const DaysConverter = (days) => {
    //console.log(days);
    return days + " days";
  };

  const MinsConverter = (mins) => {
    //console.log(mins);
    return mins + " mins";
  };



  useEffect(() => {
    fetchCurrentDiscount(setCurrentDiscount);
  }, []);

  useEffect(() => {
    fetchDiscountStatus(setDiscountEnabled);
  }, []);


  useEffect(() => {
    fetchData();
  }, [isActive]);

  useEffect(() => {
    viewUrl();
  }, []);

  const viewUrl = async () => {
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_upload_promo/view_clip",
        {
          method: "POST",

          body: JSON.stringify({ doc_type: "user" }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // getData(await response.json());
      const data = await response.json();
      setLinkU(data?.promo.clip_url);
      // setSelectedSrc(data?.promo.thumbnail_url);
      //console.log(data);
    } catch (error) {
      //console.log(error);
    }

    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_upload_promo/view_clip",
        {
          method: "POST",

          body: JSON.stringify({ doc_type: "promo" }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // getData(await response.json());
      const data = await response.json();
      setLink(data?.promo.clip_url);
      // setSelectedSrc(data?.promo.thumbnail_url);
      //console.log(data);
    } catch (error) {
      //console.log(error);
    }

    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_upload_promo/view_clip",
        {
          method: "POST",

          body: JSON.stringify({ doc_type: "rider" }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // getData(await response.json());
      const data = await response.json();
      setLinkA(data?.promo.clip_url);
      // setSelectedSrc(data?.promo.thumbnail_url);
      //console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_settings/settings",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // getData(await response.json());
      const resultM = await response.json();
      setResult(resultM);
      setIsLoaded(true);
      //console.log(isLoaded);
      // console.log(resultM);
      setData(resultM.stats);
      setIsActive(resultM.stats.active_delivery_mediums.bike);
      setIsActive1(resultM.stats.active_delivery_mediums.car);
      setIsActive2(resultM.stats.active_delivery_mediums.van);
      setIsActive3(resultM.stats.active_delivery_mediums.truck);
      if (resultM.stats.active_delivery_mediums.bike === true) {
        setBikeText("Enabled");
      } else {
        setBikeText("Disabled");
      }
      if (resultM.stats.active_delivery_mediums.car === true) {
        setCarText("Enabled");
      } else {
        setCarText("Disabled");
      }
      if (resultM.stats.active_delivery_mediums.van === true) {
        setVanText("Enabled");
      } else {
        setVanText("Disabled");
      }
      if (resultM.stats.active_delivery_mediums.truck === true) {
        setTruckText("Enabled");
      } else {
        setTruckText("Disabled");
      }
      //console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };

  const enableDeliveryMedium = async (which_medium) => {
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_settings/enable_delivery_medium",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            which_medium: which_medium,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const state = await response.json();
      //console.log(state);
      // getData(await response.json());
      // const resultM = await response.json();
      //console.log(resultM);
      // setResult(resultM)
      // setIsLoaded(true)
      // getData(resultM.reports);
      //console.log(result);
      //console.log(data);
      if (response.status === 200) {
        //console.log("data gotten succesfully");
        fetchData();
        setDisable(false);
        setDisable1(false);
        setDisable2(false);
        setDisable3(false);
      } else {
        //console.log("some error occurred");
        setDisable(false);
        setDisable1(false);
        setDisable2(false);
        setDisable3(false);
      }
    } catch (error) {
      //console.log(error);
      setDisable(false);
      setDisable1(false);
      setDisable2(false);
      setDisable3(false);
    }
  };
  const onFileChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState == 2) {
        setSelectedSrc(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
    setIsSelected(true);
  };

  const handleToggle = async (newState) => {
    try {
      setDiscountEnabled(newState);
      await updateDiscountStatus(token, newState);
    } catch (error) {
      console.error("Error updating discount status:", error);
      setDiscountEnabled((prevState) => !prevState);
    }
  };

  const disableDeliveryMedium = async (which_medium) => {
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_settings/disable_delivery_medium",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            which_medium: which_medium,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const state = await response.json();
      //console.log(state);
      // getData(await response.json());
      // const resultM = await response.json();
      //console.log(resultM);
      // setResult(resultM)
      // setIsLoaded(true)
      // getData(resultM.reports);
      //console.log(result);
      //console.log(data);
      if (response.status === 200) {
        //console.log("data gotten succesfully");
        fetchData();
        setDisable(false);
        setDisable1(false);
        setDisable2(false);
        setDisable3(false);
      } else {
        //console.log("some error occurred");
        setDisable(false);
        setDisable1(false);
        setDisable2(false);
        setDisable3(false);
      }
    } catch (error) {
      setDisable(false);
      setDisable1(false);
      setDisable2(false);
      setDisable3(false);
      //console.log(error);
    }
  };

  const handleClick = () => {
    setIsActive((current) => !current);

    //console.log(isActive);
    if (isActive === false) {
      enableDeliveryMedium("bike");
      setBikeText("Enabled");
      setDisable(true);
    } else {
      disableDeliveryMedium("bike");
      setBikeText("Disabled");
      setDisable(true);
    }
    //   setButtonText('Disabled');

    // setIsActive(true);
  };

  const handleClick1 = () => {
    setIsActive1((current) => !current);
    if (isActive1 === false) {
      enableDeliveryMedium("car");
      setCarText("Enabled");
      setDisable1(true);
    } else {
      disableDeliveryMedium("car");
      setCarText("Disabled");
      setDisable1(true);
    }
    //   setButtonText('Disabled');

    // setIsActive(true);
  };

  const handleClick2 = () => {
    setIsActive2((current) => !current);
    if (isActive2 === false) {
      enableDeliveryMedium("van");
      setVanText("Enabled");
      setDisable2(true);
    } else {
      disableDeliveryMedium("van");
      setVanText("Disabled");
      setDisable2(true);
    }
    //   setButtonText('Disabled');

    // setIsActive(true);
  };

  const handleClick3 = () => {
    setIsActive3((current) => !current);
    if (isActive3 === false) {
      enableDeliveryMedium("truck");
      setTruckText("Enabled");
      setDisable3(true);
    } else {
      disableDeliveryMedium("truck");
      setTruckText("Disabled");
      setDisable3(true);
    }
    //   setButtonText('Disabled');

    // setIsActive(true);
  };

  const handleUpload = async (url, type) => {
    type == "promo"
      ? setDisableUrl(true)
      : type == "user"
        ? setDisableUrlU(true)
        : setDisableUrlA(true);
    // e.preventDefault();

    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_upload_promo/upload_clip",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            clip_url: url,
            doc_type: type,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );

      if (response.status === 200) {
        alert("Promo url uploaded successfully");
        type == "promo"
          ? setDisableUrl(false)
          : type == "user"
            ? setDisableUrlU(false)
            : setDisableUrlA(false);
      } else {
        alert("Failed to upload promo url");
        type == "promo"
          ? setDisableUrl(false)
          : type == "user"
            ? setDisableUrlU(false)
            : setDisableUrlA(false);
      }
      //console.log(response);
    } catch (error) {
      alert("Failed to upload promo url");
      type == "promo"
        ? setDisableUrl(false)
        : type == "user"
          ? setDisableUrlU(false)
          : setDisableUrlA(false);
    }
  };


  if (!isLoaded) {
    return (
      <h1 className="loading-pages">
        <ClipLoader color={"#1FAA08"} size={100} />
      </h1>
    );
  } else if (result.msg === "Success") {
    return (
      <div className="settings">
        <div className="settings-props">
          <div className="settings-title-row">
            <div className="settings-title">Settings</div>

            <div>
              <Link to="/App/VideoSettings">
                {" "}
                <button className="settings-right-title">
                  Video Promo Settings
                </button>
              </Link>
            </div>

            {/* <div
              className="settings-right-title"
              onClick={() => navigate("/VideoSettings")}
              >
              Video Promo Settings
              </div> */}
          </div>
          <div>

            <h2 className="text-xl font-bold">
              {isInstant ? "Instant Delivery" : "Scheduled Delivery"}
            </h2>
            <ToggleSwtichSche isOn={isInstant} onToggle={setIsInstant} />
          </div>

          {isInstant ? (
            <>



              <h3 className="settings-data-title">Delivery Media</h3>
              <div className="delivery-settings-medium">
                <div className="delivery-vehicle-medium">
                  <div className="delivery-bikes">
                    <div className="bikes-medium">
                      <img src={bikes} alt="" className="delivery-motor" />
                    </div>
                    <button
                      disabled={disable}
                      className="update-medium"
                      style={{
                        backgroundColor: isActive
                          ? "#1aa803"
                          : "rgba(153, 153, 153, 1)",
                        color: isActive ? "white" : "white",
                      }}
                      onClick={handleClick}
                    >
                      {disable ? (
                        <ClipLoader color={"black"} loading={disable} size={15} />
                      ) : (
                        bike_text
                      )}
                    </button>
                  </div>
                  <div className="delivery-bikes">
                    <div className="bikes-medium">
                      <img src={cars} alt="" className="delivery-motor" />
                    </div>
                    <button
                      disabled={disable1}
                      className="update-medium"
                      style={{
                        backgroundColor: isActive1
                          ? "#1aa803"
                          : "rgba(153, 153, 153, 1)",
                        color: isActive ? "white" : "white",
                      }}
                      onClick={handleClick1}
                    >
                      {disable1 ? (
                        <ClipLoader color={"black"} loading={disable1} size={15} />
                      ) : (
                        car_text
                      )}
                    </button>
                  </div>
                  <div className="delivery-bikes">
                    <div className="bikes-medium">
                      <img src={van} alt="" className="delivery-motor" />
                    </div>
                    <button
                      disabled={disable2}
                      className="update-medium"
                      style={{
                        backgroundColor: isActive2
                          ? "#1aa803"
                          : "rgba(153, 153, 153, 1)",
                        color: isActive ? "white" : "white",
                      }}
                      onClick={handleClick2}
                    >
                      {disable2 ? (
                        <ClipLoader color={"black"} loading={disable2} size={15} />
                      ) : (
                        van_text
                      )}
                    </button>
                  </div>
                  <div className="delivery-bikes">
                    <div className="bikes-medium">
                      <img src={trucks} alt="" className="delivery-motor" />
                    </div>
                    <button
                      disabled={disable3}
                      className="update-medium"
                      style={{
                        backgroundColor: isActive3
                          ? "#1aa803"
                          : "rgba(153, 153, 153, 1)",
                        color: isActive ? "white" : "white",
                      }}
                      onClick={handleClick3}
                    >
                      {disable3 ? (
                        <ClipLoader color={"black"} loading={disable3} size={15} />
                      ) : (
                        truck_text
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <h3 className="settings-data-title">
                Maximum distance range to notify Delivery Agents
              </h3>
              <div className="distance-measurement">
                <div className="distance">
                  <label className="settings-detail-title">
                    Previous range (meters){" "}
                  </label>
                  <form>
                    <div className="settings-input7" type="number">
                      {" "}
                      {KmConverter(data?.pickup_radius)}{" "}
                    </div>
                  </form>
                </div>
                <div className="distance">
                  <label className="settings-detail-title">
                    New range (meters){" "}
                  </label>
                  <form>
                    <input
                      className="settings-input"
                      type="number"
                      value={radius}
                      onChange={(e) => setRadius(e.target.value)}
                    />
                  </form>
                </div>
                <div>
                  <button
                    className="settings-activate1"
                    disabled={disable4}
                    onClick={() =>
                      updatePickupRadius(token, radius, fetchData, setDisable4)
                    }
                  >
                    {disable4 ? (
                      <ClipLoader color={"black"} loading={disable4} size={15} />
                    ) : (
                      "Update"
                    )}
                  </button>
                </div>
              </div>

              <h3 className="settings-data-title">Meter Rates</h3>
              <div className="delivery-settings-medium2">
                <div className="delivery-vehicle-medium2">
                  {/* <div className='delivery-bikes1'>
                                <div className='bikes-medium1'>
                                    <img src={bikes} alt='' className='delivery-motor1' />
                                </div>
                            </div> */}
                  <div className="percentage-rows">
                    <div className="previous-flat-rates">
                      <div className="distance1">
                        <h2 className="distance1-title">Bike</h2>
                        <label className="settings-detail-title2">
                          Previous Meter rates
                        </label>
                        <form>
                          <div className="settings-input1" type="number">
                            {" "}
                            {Converter(data?.km_rate?.bike)}{" "}
                          </div>
                        </form>
                      </div>
                      <div className="distance1">
                        <label className="settings-detail-title2">
                          New Meter rates
                        </label>
                        <form>
                          <input
                            className="settings-input5"
                            type="number"
                            value={bike_km_rate}
                            onChange={(e) => setBikeKmRate(e.target.value)}
                          />
                        </form>
                        <div>
                          <button
                            className="settings-activate5"
                            disabled={disable5}
                            onClick={() =>
                              updateKmRate(
                                token,
                                "bike",
                                bike_km_rate,
                                fetchData,
                                setDisable5
                              )
                            }
                          >
                            {disable5 ? (
                              <ClipLoader
                                color={"black"}
                                loading={disable5}
                                size={15}
                              />
                            ) : (
                              "Update"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <div className='delivery-bikes1'>
                                    <div className='bikes-medium'>
                                        <img src={cars} alt='' className='delivery-motor1' />
                                    </div>
                                </div> */}
                    <div className="previous-flat-rates">
                      <div className="distance1">
                        <h2 className="distance1-title">Car</h2>
                        <label className="settings-detail-title2">
                          Previous Meter rates
                        </label>
                        <form>
                          <div className="settings-input1" type="number">
                            {" "}
                            {Converter(data?.km_rate?.car)}{" "}
                          </div>
                        </form>
                      </div>
                      <div className="distance1">
                        <label className="settings-detail-title2">
                          New Meter rates
                        </label>
                        <form>
                          <input
                            className="settings-input5"
                            type="number"
                            value={car_km_rate}
                            onChange={(e) => setCarKmRate(e.target.value)}
                          />
                        </form>
                        <div>
                          <button
                            className="settings-activate5"
                            disabled={disable6}
                            onClick={() =>
                              updateKmRate(
                                token,
                                "car",
                                car_km_rate,
                                fetchData,
                                setDisable6
                              )
                            }
                          >
                            {disable6 ? (
                              <ClipLoader
                                color={"black"}
                                loading={disable6}
                                size={15}
                              />
                            ) : (
                              "Update"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <div className='delivery-bikes1'>
                                    <div className='bikes-medium'>
                                        <img src={van} alt='' className='delivery-motor1' />
                                    </div>
                                </div> */}
                  </div>
                  <div className="percentage-rows">
                    <div className="previous-flat-rates">
                      <div className="distance1">
                        <h2 className="distance1-title">Van</h2>
                        <label className="settings-detail-title2">
                          Previous Meter rates
                        </label>
                        <form>
                          <div className="settings-input1" type="number">
                            {" "}
                            {Converter(data?.km_rate?.van)}{" "}
                          </div>
                        </form>
                      </div>
                      <div className="distance1">
                        <label className="settings-detail-title2">
                          New Meter rates
                        </label>
                        <form>
                          <input
                            className="settings-input5"
                            type="number"
                            value={van_km_rate}
                            onChange={(e) => setVanKmRate(e.target.value)}
                          />
                        </form>
                        <div>
                          <button
                            className="settings-activate5"
                            disabled={disable7}
                            onClick={() =>
                              updateKmRate(
                                token,
                                "van",
                                van_km_rate,
                                fetchData,
                                setDisable7
                              )
                            }
                          >
                            {disable7 ? (
                              <ClipLoader
                                color={"black"}
                                loading={disable7}
                                size={15}
                              />
                            ) : (
                              "Update"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <div className='delivery-bikes1'>
                                    <div className='bikes-medium'>
                                        <img src={trucks} alt='' className='delivery-motor1' />
                                    </div>
                                </div> */}
                    <div className="previous-flat-rates">
                      <div className="distance1">
                        <h2 className="distance1-title">Truck</h2>
                        <label className="settings-detail-title2">
                          Previous Meter rates
                        </label>
                        <form>
                          <div className="settings-input1" type="number">
                            {" "}
                            {Converter(data?.km_rate?.truck)}{" "}
                          </div>
                        </form>
                      </div>
                      <div className="distance1">
                        <label className="settings-detail-title2">
                          New Meter rates
                        </label>
                        <form>
                          <input
                            className="settings-input5"
                            type="number"
                            value={truck_km_rate}
                            onChange={(e) => setTruckKmRate(e.target.value)}
                          />
                        </form>
                        <div>
                          <button
                            className="settings-activate5"
                            disabled={disable8}
                            onClick={() =>
                              updateKmRate(
                                token,
                                "truck",
                                truck_km_rate,
                                fetchData,
                                setDisable8
                              )
                            }
                          >
                            {disable8 ? (
                              <ClipLoader
                                color={"black"}
                                loading={disable8}
                                size={15}
                              />
                            ) : (
                              "Update"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h3 className="settings-data-title">Time Rate</h3>
              <div className="delivery-settings-medium2">
                <div className="delivery-vehicle-medium2">
                  {/* <div className='delivery-bikes1'>
                                <div className='bikes-medium1'>
                                    <img src={bikes} alt='' className='delivery-motor1' />
                                </div>
                            </div> */}
                  <div className="percentage-rows">
                    <div className="previous-flat-rates">
                      <div className="distance1">
                        <h2 className="distance1-title">Bike</h2>
                        <label className="settings-detail-title2">
                          Previous Time rates
                        </label>
                        <form>
                          <div className="settings-input1" type="number">
                            {" "}
                            {Converter(data?.time_rate?.bike)}{" "}
                          </div>
                        </form>
                      </div>
                      <div className="distance1">
                        <label className="settings-detail-title2">
                          New Time rates
                        </label>
                        <form>
                          <input
                            className="settings-input5"
                            disabled={disable9}
                            type="number"
                            value={bike_time_rate}
                            onChange={(e) => setBikeTimeRate(e.target.value)}
                          />
                        </form>
                        <div>
                          <button
                            className="settings-activate5"
                            onClick={() =>
                              updateTimeRate(
                                token,
                                "bike",
                                bike_time_rate,
                                fetchData,
                                setDisable9
                              )
                            }
                          >
                            {disable9 ? (
                              <ClipLoader
                                color={"black"}
                                loading={disable9}
                                size={15}
                              />
                            ) : (
                              "Update"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <div className='delivery-bikes1'>
                                    <div className='bikes-medium'>
                                        <img src={cars} alt='' className='delivery-motor1' />
                                    </div>
                                </div> */}
                    <div className="previous-flat-rates">
                      <div className="distance1">
                        <h2 className="distance1-title">Car</h2>
                        <label className="settings-detail-title2">
                          Previous Time rates
                        </label>
                        <form>
                          <div className="settings-input1" type="number">
                            {" "}
                            {Converter(data?.time_rate?.car)}{" "}
                          </div>
                        </form>
                      </div>
                      <div className="distance1">
                        <label className="settings-detail-title2">
                          New Time rates
                        </label>
                        <form>
                          <input
                            className="settings-input5"
                            type="number"
                            value={car_time_rate}
                            onChange={(e) => setCarTimeRate(e.target.value)}
                          />
                        </form>
                        <div>
                          <button
                            className="settings-activate5"
                            disabled={disable10}
                            onClick={() =>
                              updateTimeRate(
                                token,
                                "car",
                                car_time_rate,
                                fetchData,
                                setDisable10
                              )
                            }
                          >
                            {disable10 ? (
                              <ClipLoader
                                color={"black"}
                                loading={disable10}
                                size={15}
                              />
                            ) : (
                              "Update"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <div className='delivery-bikes1'>
                                    <div className='bikes-medium'>
                                        <img src={van} alt='' className='delivery-motor1' />
                                    </div>
                                </div> */}
                  </div>
                  <div className="percentage-rows">
                    <div className="previous-flat-rates">
                      <div className="distance1">
                        <h2 className="distance1-title">Van</h2>
                        <label className="settings-detail-title2">
                          Previous Time rates
                        </label>
                        <form>
                          <div className="settings-input1" type="number">
                            {" "}
                            {Converter(data?.time_rate?.van)}{" "}
                          </div>
                        </form>
                      </div>
                      <div className="distance1">
                        <label className="settings-detail-title2">
                          New Time rates
                        </label>
                        <form>
                          <input
                            className="settings-input5"
                            type="number"
                            value={van_time_rate}
                            onChange={(e) => setVanTimeRate(e.target.value)}
                          />
                        </form>
                        <div>
                          <button
                            className="settings-activate5"
                            disabled={disable11}
                            onClick={() =>
                              updateTimeRate(
                                token,
                                "van",
                                van_time_rate,
                                fetchData,
                                setDisable11
                              )
                            }
                          >
                            {disable11 ? (
                              <ClipLoader
                                color={"black"}
                                loading={disable11}
                                size={15}
                              />
                            ) : (
                              "Update"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <div className='delivery-bikes1'>
                                    <div className='bikes-medium'>
                                        <img src={trucks} alt='' className='delivery-motor1' />
                                    </div>
                                </div> */}
                    <div className="previous-flat-rates">
                      <div className="distance1">
                        <h2 className="distance1-title">Truck</h2>
                        <label className="settings-detail-title2">
                          Previous Time rates
                        </label>
                        <form>
                          <div className="settings-input1" type="number">
                            {" "}
                            {Converter(data?.time_rate?.truck)}{" "}
                          </div>
                        </form>
                      </div>
                      <div className="distance1">
                        <label className="settings-detail-title2">
                          New Time rates
                        </label>
                        <form>
                          <input
                            className="settings-input5"
                            type="number"
                            value={truck_time_rate}
                            onChange={(e) => setTrucktimeRate(e.target.value)}
                          />
                        </form>
                        <div>
                          <button
                            className="settings-activate5"
                            disabled={disable12}
                            onClick={() =>
                              updateTimeRate(
                                token,
                                "truck",
                                truck_time_rate,
                                fetchData,
                                setDisable12
                              )
                            }
                          >
                            {disable12 ? (
                              <ClipLoader
                                color={"black"}
                                loading={disable12}
                                size={15}
                              />
                            ) : (
                              "Update"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h3 className="settings-data-title">Base Fare</h3>
              <div className="delivery-settings-medium2">
                <div className="delivery-vehicle-medium2">
                  {/* <div className='delivery-bikes1'>
                                <div className='bikes-medium1'>
                                    <img src={bikes} alt='' className='delivery-motor1' />
                                </div>
                            </div> */}
                  <div className="percentage-rows">
                    <div className="previous-flat-rates">
                      <div className="distance1">
                        <h2 className="distance1-title">Bike</h2>
                        <label className="settings-detail-title2">
                          Previous Base Fare
                        </label>
                        <form>
                          <div className="settings-input1" type="number">
                            {" "}
                            {Converter(data?.base_fare?.bike)}{" "}
                          </div>
                        </form>
                      </div>
                      <div className="distance1">
                        <label className="settings-detail-title2">
                          New Base Fare
                        </label>
                        <form>
                          <input
                            className="settings-input5"
                            type="number"
                            value={bike_base_fare}
                            onChange={(e) => setBikeBaseFare(e.target.value)}
                          />
                        </form>
                        <div>
                          <button
                            className="settings-activate5"
                            disabled={disable13}
                            onClick={() =>
                              updateBaseFare(
                                token,
                                "bike",
                                bike_base_fare,
                                fetchData,
                                setDisable13
                              )
                            }
                          >
                            {disable13 ? (
                              <ClipLoader
                                color={"black"}
                                loading={disable13}
                                size={15}
                              />
                            ) : (
                              "Update"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <div className='delivery-bikes1'>
                                    <div className='bikes-medium'>
                                        <img src={cars} alt='' className='delivery-motor1' />
                                    </div>
                                </div> */}
                    <div className="previous-flat-rates">
                      <div className="distance1">
                        <h2 className="distance1-title">Car</h2>
                        <label className="settings-detail-title2">
                          Previous Base Fare
                        </label>
                        <form>
                          <div className="settings-input1" type="number">
                            {" "}
                            {Converter(data?.base_fare?.car)}{" "}
                          </div>
                        </form>
                      </div>
                      <div className="distance1">
                        <label className="settings-detail-title2">
                          New Base Fare
                        </label>
                        <form>
                          <input
                            className="settings-input5"
                            type="number"
                            value={car_base_fare}
                            onChange={(e) => setCarBaseFare(e.target.value)}
                          />
                        </form>
                        <div>
                          <button
                            className="settings-activate5"
                            disabled={disable14}
                            onClick={() =>
                              updateBaseFare(
                                token,
                                "car",
                                car_base_fare,
                                fetchData,
                                setDisable14
                              )
                            }
                          >
                            {disable14 ? (
                              <ClipLoader
                                color={"black"}
                                loading={disable14}
                                size={15}
                              />
                            ) : (
                              "Update"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <div className='delivery-bikes1'>
                                    <div className='bikes-medium'>
                                        <img src={van} alt='' className='delivery-motor1' />
                                    </div>
                                </div> */}
                  </div>
                  <div className="percentage-rows">
                    <div className="previous-flat-rates">
                      <div className="distance1">
                        <h2 className="distance1-title">Van</h2>
                        <label className="settings-detail-title2">
                          Previous Base Fare
                        </label>
                        <form>
                          <div className="settings-input1" type="number">
                            {" "}
                            {Converter(data?.base_fare?.van)}{" "}
                          </div>
                        </form>
                      </div>
                      <div className="distance1">
                        <label className="settings-detail-title2">
                          New Base Fare
                        </label>
                        <form>
                          <input
                            className="settings-input5"
                            type="number"
                            value={van_base_fare}
                            onChange={(e) => setVanBaseFare(e.target.value)}
                          />
                        </form>
                        <div>
                          <button
                            className="settings-activate5"
                            disabled={disable15}
                            onClick={() =>
                              updateBaseFare(
                                token,
                                "van",
                                van_base_fare,
                                fetchData,
                                setDisable15
                              )
                            }
                          >
                            {disable15 ? (
                              <ClipLoader
                                color={"black"}
                                loading={disable15}
                                size={15}
                              />
                            ) : (
                              "Update"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <div className='delivery-bikes1'>
                                    <div className='bikes-medium'>
                                        <img src={trucks} alt='' className='delivery-motor1' />
                                    </div>
                                </div> */}
                    <div className="previous-flat-rates">
                      <div className="distance1">
                        <h2 className="distance1-title">Truck</h2>
                        <label className="settings-detail-title2">
                          Previous Base Fare
                        </label>
                        <form>
                          <div className="settings-input1" type="number">
                            {" "}
                            {Converter(data?.base_fare?.truck)}{" "}
                          </div>
                        </form>
                      </div>
                      <div className="distance1">
                        <label className="settings-detail-title2">
                          New Base Fare
                        </label>
                        <form>
                          <input
                            className="settings-input5"
                            type="number"
                            value={truck_base_fare}
                            onChange={(e) => setTruckBaseFare(e.target.value)}
                          />
                        </form>
                        <div>
                          <button
                            className="settings-activate5"
                            disabled={disable16}
                            onClick={() =>
                              updateBaseFare(
                                token,
                                "truck",
                                truck_base_fare,
                                fetchData,
                                setDisable16
                              )
                            }
                          >
                            {disable16 ? (
                              <ClipLoader
                                color={"black"}
                                loading={disable16}
                                size={15}
                              />
                            ) : (
                              "Update"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h3 className="settings-data-title">Minimum price </h3>
              <div className="delivery-settings-medium2">
                <div className="delivery-vehicle-medium2">
                  {/* <div className='delivery-bikes1'>
                                <div className='bikes-medium1'>
                                    <img src={bikes} alt='' className='delivery-motor1' />
                                </div>
                            </div> */}
                  <div className="percentage-rows">
                    <div className="previous-flat-rates">
                      <div className="distance1">
                        <h2 className="distance1-title">Bike</h2>
                        <label className="settings-detail-title2">
                          Previous Min Price{" "}
                        </label>
                        <form>
                          <div className="settings-input1" type="number">
                            {" "}
                            {Converter(data?.min_price?.bike)}{" "}
                          </div>
                        </form>
                      </div>
                      <div className="distance1">
                        <label className="settings-detail-title2">
                          New Min Price{" "}
                        </label>
                        <form>
                          <input
                            className="settings-input5"
                            type="number"
                            value={bike_min_rate}
                            onChange={(e) => setBikeMinRate(e.target.value)}
                          />
                        </form>
                        <div>
                          <button
                            className="settings-activate5"
                            disabled={disable17}
                            onClick={() =>
                              updateMinPrice(
                                token,
                                "bike",
                                bike_min_rate,
                                fetchData,
                                setDisable17
                              )
                            }
                          >
                            {disable17 ? (
                              <ClipLoader
                                color={"black"}
                                loading={disable17}
                                size={15}
                              />
                            ) : (
                              "Update"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <div className='delivery-bikes1'>
                                    <div className='bikes-medium'>
                                        <img src={cars} alt='' className='delivery-motor1' />
                                    </div>
                                </div> */}
                    <div className="previous-flat-rates">
                      <div className="distance1">
                        <h2 className="distance1-title">Car</h2>
                        <label className="settings-detail-title2">
                          Previous Min Price{" "}
                        </label>
                        <form>
                          <div className="settings-input1" type="number">
                            {" "}
                            {Converter(data?.min_price?.car)}{" "}
                          </div>
                        </form>
                      </div>
                      <div className="distance1">
                        <label className="settings-detail-title2">
                          New Min Price{" "}
                        </label>
                        <form>
                          <input
                            className="settings-input5"
                            type="number"
                            value={car_min_rate}
                            onChange={(e) => setCarMinRate(e.target.value)}
                          />
                        </form>
                        <div>
                          <button
                            className="settings-activate5"
                            disabled={disable18}
                            onClick={() =>
                              updateMinPrice(
                                token,
                                "car",
                                car_min_rate,
                                fetchData,
                                setDisable18
                              )
                            }
                          >
                            {disable18 ? (
                              <ClipLoader
                                color={"black"}
                                loading={disable18}
                                size={15}
                              />
                            ) : (
                              "Update"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <div className='delivery-bikes1'>
                                    <div className='bikes-medium'>
                                        <img src={van} alt='' className='delivery-motor1' />
                                    </div>
                                </div> */}
                  </div>
                  <div className="percentage-rows">
                    <div className="previous-flat-rates">
                      <div className="distance1">
                        <h2 className="distance1-title">Van</h2>
                        <label className="settings-detail-title2">
                          Previous Min Price{" "}
                        </label>
                        <form>
                          <div className="settings-input1" type="number">
                            {" "}
                            {Converter(data?.min_price?.van)}{" "}
                          </div>
                        </form>
                      </div>
                      <div className="distance1">
                        <label className="settings-detail-title2">
                          New Min Price{" "}
                        </label>
                        <form>
                          <input
                            className="settings-input5"
                            type="number"
                            value={van_min_rate}
                            onChange={(e) => setVanMinRate(e.target.value)}
                          />
                        </form>
                        <div>
                          <button
                            className="settings-activate5"
                            disabled={disable19}
                            onClick={() =>
                              updateMinPrice(
                                token,
                                "van",
                                van_min_rate,
                                fetchData,
                                setDisable19
                              )
                            }
                          >
                            {disable19 ? (
                              <ClipLoader
                                color={"black"}
                                loading={disable19}
                                size={15}
                              />
                            ) : (
                              "Update"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <div className='delivery-bikes1'>
                                    <div className='bikes-medium'>
                                        <img src={trucks} alt='' className='delivery-motor1' />
                                    </div>
                                </div> */}
                    <div className="previous-flat-rates">
                      <div className="distance1">
                        <h2 className="distance1-title">Truck</h2>
                        <label className="settings-detail-title2">
                          Previous Min Price{" "}
                        </label>
                        <form>
                          <div className="settings-input1" type="number">
                            {" "}
                            {Converter(data?.min_price?.truck)}{" "}
                          </div>
                        </form>
                      </div>
                      <div className="distance1">
                        <label className="settings-detail-title2">
                          New Min Price{" "}
                        </label>
                        <form>
                          <input
                            className="settings-input5"
                            type="number"
                            value={truck_min_rate}
                            onChange={(e) => setTruckMinRate(e.target.value)}
                          />
                        </form>
                        <div>
                          <button
                            className="settings-activate5"
                            disabled={disable20}
                            onClick={() =>
                              updateMinPrice(
                                token,
                                "truck",
                                truck_min_rate,
                                fetchData,
                                setDisable20
                              )
                            }
                          >
                            {disable20 ? (
                              <ClipLoader
                                color={"black"}
                                loading={disable20}
                                size={15}
                              />
                            ) : (
                              "Update"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h3 className="settings-data-title">
                Percentage refunds on Cancelled deliveries
              </h3>
              <div className="distance-measurement2">
                <div className="percentage-rows">
                  <div className="distance2">
                    <label className="settings-detail-title">
                      Previous % to users
                    </label>
                    <form>
                      <div className="settings-input1" type="number">
                        {" "}
                        {PercentageConverter(data?.refund_percent)}{" "}
                      </div>
                    </form>
                  </div>

                  <div className="distance2">
                    <label className="settings-detail-title">New % to users</label>
                    <form>
                      <input
                        className="settings-input5"
                        type="number"
                        value={user_ref_percent}
                        onChange={(e) => setUserRefPercent(e.target.value)}
                      />
                    </form>
                    <div>
                      <button
                        className="settings-activate5"
                        disabled={disable21}
                        onClick={() =>
                          updateRefRate(
                            token,
                            "user",
                            user_ref_percent,
                            fetchData,
                            setDisable21
                          )
                        }
                      >
                        {disable21 ? (
                          <ClipLoader
                            color={"black"}
                            loading={disable21}
                            size={15}
                          />
                        ) : (
                          "Update"
                        )}
                      </button>
                    </div>
                  </div>



                </div>

              </div>
              <div >

                <div className="flex justify-between   items-center">
                  <div>
                    <h3 className="settings-data-title text-red-700">
                      Percentage for Discount Price
                    </h3>
                    <div className="distance-measurement2">
                      <div className="percentage-rows">
                        <div className="distance2">
                          <label className="settings-detail-title">
                            Previous % for discount
                          </label>
                          <form>
                            <div className="settings-input1" type="number">
                              {" "}
                              {currentDiscount !== null ? `${currentDiscount}%` : " "}
                            </div>
                          </form>
                        </div>

                        <div className="distance2">
                          <label className="settings-detail-title">New % for disount</label>
                          <form>
                            <input
                              className="settings-input5"
                              type="number"
                              value={discount_percentage}
                              onChange={(e) => setDeliveryPercentage(e.target.value)}
                            />
                          </form>
                          <div>
                            <button
                              className="settings-activate5"
                              disabled={disable27}
                              onClick={() =>
                                updatepercentageDiscount(
                                  token,
                                  discount_percentage,
                                  fetchData,
                                  setDisable27,
                                  setCurrentDiscount
                                )
                              }
                            >
                              {disable27 ? (
                                <ClipLoader
                                  color={"black"}
                                  loading={disable27}
                                  size={15}
                                />
                              ) : (
                                "Update"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>



                    </div>
                  </div>



                  <div className="pr-10">
                    <ToggleSwitch isOn={discountEnabled} onToggle={handleToggle} />
                  </div>
                </div>

              </div>

              <h3 className="settings-data-title">
                Pickload Percent on Deliveries
              </h3>
              <div className="distance-measurement">
                <div className="distance-measurement3">
                  <div className="distance">
                    <label className="settings-detail-title">
                      Previous Pickload Percent
                    </label>
                    <form>
                      <div className="settings-input4" type="number">
                        {" "}
                        {PercentageConverter(data?.pickload_percent)}{" "}
                      </div>
                    </form>
                  </div>
                  <div className="distance">
                    <label className="settings-detail-title">
                      New Pickload Percent
                    </label>
                    <form>
                      <input
                        className="settings-input5"
                        type="number"
                        value={pickload_percent}
                        onChange={(e) => setPickloadPercent(e.target.value)}
                      />
                    </form>
                  </div>
                </div>
                <div>
                  <button
                    className="settings-activate1"
                    disabled={disable23}
                    onClick={() =>
                      updatePickloadPercent(
                        token,
                        pickload_percent,
                        fetchData,
                        setDisable23
                      )
                    }
                  >
                    {disable23 ? (
                      <ClipLoader color={"black"} loading={disable23} size={15} />
                    ) : (
                      "Update"
                    )}
                  </button>
                </div>
              </div>

              <h3 className="settings-data-title">
                Delivery Request Timeout Duration
              </h3>
              <div className="distance-measurement">
                <div className="distance-measurement3">
                  <div className="distance">
                    <label className="settings-detail-title">
                      Previous Duration
                    </label>
                    <form>
                      <div className="settings-input4" type="number">
                        {" "}
                        {MinsConverter(data?.request_timeout_duration)}{" "}
                      </div>
                    </form>
                  </div>
                  <div className="distance">
                    <label className="settings-detail-title">New Duration</label>
                    <form>
                      <input
                        className="settings-input5"
                        type="number"
                        value={delivery_wait_time}
                        onChange={(e) => setDeliveryWaitTime(e.target.value)}
                      />
                    </form>
                  </div>
                </div>
                <div>
                  <button
                    className="settings-activate1"
                    disabled={disable24}
                    onClick={() =>
                      updateDeliveryWaitTime(
                        token,
                        delivery_wait_time,
                        fetchData,
                        setDisable24
                      )
                    }
                  >
                    {disable24 ? (
                      <ClipLoader color={"black"} loading={disable24} size={15} />
                    ) : (
                      "Update"
                    )}
                  </button>
                </div>
              </div>

              <h3 className="settings-data-title">
                Payment Request Timeout Duration
              </h3>
              <div className="distance-measurement">
                <div className="distance-measurement3">
                  <div className="distance">
                    <label className="settings-detail-title">
                      Previous Duration
                    </label>
                    <form>
                      <div className="settings-input4" type="number">
                        {" "}
                        {MinsConverter(data?.payment_timeout_duration)}{" "}
                      </div>
                    </form>
                  </div>
                  <div className="distance">
                    <label className="settings-detail-title">New Duration</label>
                    <form>
                      <input
                        className="settings-input5"
                        type="number"
                        value={payment_timeout_duration}
                        onChange={(e) => setPaymentTimeOutDuration(e.target.value)}
                      />
                    </form>
                  </div>
                </div>
                <div>
                  <button
                    className="settings-activate1"
                    disabled={disable25}
                    onClick={() =>
                      updatePaymentTimeoutDuration(
                        token,
                        payment_timeout_duration,
                        fetchData,
                        setDisable25
                      )
                    }
                  >
                    {disable25 ? (
                      <ClipLoader color={"black"} loading={disable25} size={15} />
                    ) : (
                      "Update"
                    )}
                  </button>
                </div>
              </div>

              <h3 className="settings-data-title">
                Number of working days to process refund
              </h3>
              <div className="distance-measurement">
                <div className="distance-measurement3">
                  <div className="distance">
                    <label className="settings-detail-title">
                      Previous number of days
                    </label>
                    <form>
                      <div className="settings-input4" type="number">
                        {" "}
                        {DaysConverter(data?.refund_days)}{" "}
                      </div>
                    </form>
                  </div>
                  <div className="distance">
                    <label className="settings-detail-title">
                      New number of days
                    </label>
                    <form>
                      <input
                        className="settings-input5"
                        type="number"
                        value={ref_days}
                        onChange={(e) => setRefDays(e.target.value)}
                      />
                    </form>
                  </div>
                </div>
                <div>
                  <button
                    className="settings-activate1"
                    disabled={disable26}
                    onClick={() =>
                      updateRefundDays(token, ref_days, fetchData, setDisable26)
                    }
                  >
                    {disable26 ? (
                      <ClipLoader color={"black"} loading={disable26} size={15} />
                    ) : (
                      "Update"
                    )}
                  </button>
                </div>
              </div>
            </>
          )
            :
            < Scheduled_setting />
          }

          {/* <h3 className="settings-data-title">Upload new promo video</h3> */}
          {/* <div className="distance-measurement">
            <div className="distance-measurement3">
              <div className="distance">
                <label className="settings-detail-title">
                  Previous video url
                </label>
                <form>
                  <div
                    className="settings-input4"
                    type="number"
                    style={{ overflow: "hidden", height: "50px" }}
                  >
                    {link}
                  </div>
                </form>
              </div>
              <div className="distance">
                <label className="settings-detail-title">New video url</label>
                <form>
                  <input
                    className="settings-input5"
                    type="text"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                  />
                </form>
              </div>
            </div>
            <div>
              <button
                className="settings-activate1"
                disabled={disableUrl}
                onClick={() => handleUpload(url, "promo")}
              >
                {disableUrl ? (
                  <ClipLoader color={"black"} loading={disableUrl} size={15} />
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </div> */}

          {/* <h3 className="settings-data-title">Upload new user app video</h3>
          <div className="distance-measurement">
            <div className="distance-measurement3">
              <div className="distance">
                <label className="settings-detail-title">
                  Previous video url
                </label>
                <form>
                  <div
                    className="settings-input4"
                    type="number"
                    style={{ overflow: "hidden", height: "50px" }}
                  >
                    {linkU}
                  </div>
                </form>
              </div>
              <div className="distance">
                <label className="settings-detail-title">New video url</label>
                <form>
                  <input
                    className="settings-input5"
                    type="text"
                    value={urlU}
                    onChange={(e) => setUrlU(e.target.value)}
                  />
                </form>
              </div>
            </div>
            <div>
              <button
                className="settings-activate1"
                disabled={disableUrlU}
                onClick={() => handleUpload(urlU, "user")}
              >
                {disableUrlU ? (
                  <ClipLoader color={"black"} loading={disableUrlU} size={15} />
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </div> */}

          {/*       <h3 className="settings-data-title">Upload new agent app video</h3>
          <div className="distance-measurement">
            <div className="distance-measurement3">
              <div className="distance">
                <label className="settings-detail-title">
                  Previous video url
                </label>
                <form>
                  <div
                    className="settings-input4"
                    type="number"
                    style={{ overflow: "hidden", height: "50px" }}
                  >
                    {linkA}
                  </div>
                </form>
              </div>
              <div className="distance">
                <label className="settings-detail-title">New video url</label>
                <form>
                  <input
                    className="settings-input5"
                    type="text"
                    value={urlA}
                    onChange={(e) => setUrlA(e.target.value)}
                  />
                </form>
              </div>
            </div>
            <div>
              <button
                className="settings-activate1"
                disabled={disableUrlA}
                onClick={() => handleUpload(urlA, "rider")}
              >
                {disableUrlA ? (
                  <ClipLoader color={"black"} loading={disableUrlA} size={15} />
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </div> */}

        </div>
      </div>
    );
  }
}
