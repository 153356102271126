import React, { useState, useEffect } from "react";
import "../../component/css/settings.css";
import bikes from "../../component/images/bikes.png";
import cars from "../../component/images/cars.png";
import trucks from "../../component/images/trucks.png";
import van from "../../component/images/van.png";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate, Link } from "react-router-dom";
import {

  updateBaseFare,
  updateKmRate,
  updateTimeRate,
  updateMinPrice,

  fetchCurrentDiscount,
  fetchDiscountStatus,
  updateScheduleKmRate,
  updateScheduleTimeRate,
  updateScheduleBaseFare,
  updateScheduleMinPrice,
} from "./settingsFunctions";
import ToggleSwitch from "./components/ToggleSwitch";
import ToggleSwtichSche from "./components/ToggleSwtichSche";
import Scheduled_setting from "./schedule_setting";

const ScheduledSetting = () => {

  const token = JSON.parse(sessionStorage.getItem("userToken"));
  // const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [result, setResult] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [bike_min_rate, setBikeMinRate] = useState("");
  const [car_min_rate, setCarMinRate] = useState("");
  const [van_min_rate, setVanMinRate] = useState("");
  const [truck_min_rate, setTruckMinRate] = useState("");
  const [bike_km_rate, setBikeKmRate] = useState("");
  const [car_km_rate, setCarKmRate] = useState("");
  const [van_km_rate, setVanKmRate] = useState("");
  const [truck_km_rate, setTruckKmRate] = useState("");
  const [bike_time_rate, setBikeTimeRate] = useState("");
  const [car_time_rate, setCarTimeRate] = useState("");
  const [van_time_rate, setVanTimeRate] = useState("");
  const [truck_time_rate, setTrucktimeRate] = useState("");
  const [bike_base_fare, setBikeBaseFare] = useState("");
  const [car_base_fare, setCarBaseFare] = useState("");
  const [van_base_fare, setVanBaseFare] = useState("");

  const [truck_base_fare, setTruckBaseFare] = useState("");
  const [bike_text, setBikeText] = useState("Enabled");
  const [car_text, setCarText] = useState("Enabled");
  const [van_text, setVanText] = useState("Enabled");
  const [truck_text, setTruckText] = useState("Enabled");
  const [disable, setDisable] = useState(false);
  const [disable1, setDisable1] = useState(false);
  const [disable2, setDisable2] = useState(false);
  const [disable3, setDisable3] = useState(false);
  const [disable5, setDisable5] = useState(false);
  const [disable6, setDisable6] = useState(false);
  const [disable7, setDisable7] = useState(false);
  const [disable8, setDisable8] = useState(false);
  const [disable9, setDisable9] = useState(false);
  const [disable10, setDisable10] = useState(false);
  const [disable11, setDisable11] = useState(false);
  const [disable12, setDisable12] = useState(false);
  const [disable13, setDisable13] = useState(false);
  const [disable14, setDisable14] = useState(false);
  const [disable15, setDisable15] = useState(false);
  const [disable16, setDisable16] = useState(false);
  const [disable17, setDisable17] = useState(false);
  const [disable18, setDisable18] = useState(false);
  const [disable19, setDisable19] = useState(false);
  const [disable20, setDisable20] = useState(false);

  const [link, setLink] = useState("");
  const [disableUrlU, setDisableUrlU] = useState(false);
  const [urlU, setUrlU] = useState("");
  const [linkU, setLinkU] = useState("");
  const [disableUrlA, setDisableUrlA] = useState(false);
  const [urlA, setUrlA] = useState("");
  const [linkA, setLinkA] = useState("");

  const [currentDiscount, setCurrentDiscount] = useState(null);

  const [discountEnabled, setDiscountEnabled] = useState(null)
  const [discountPercentageRetrive, setDiscountPercentageRetrive] = useState('')

  const [isInstant, setIsInstant] = useState(true); // true = Instant Delivery, false = Scheduled


  const Converter = (amount) => {
    if (!amount && amount !== 0) return ""; // Return empty string if amount is null/undefined
    
    return "₦" + Number(amount).toLocaleString();
  };
  

  const KmConverter = (amount) => {
    //console.log(amount)
    return amount.toLocaleString() + " meters";
  };

  const PercentageConverter = (num) => {
    //console.log(num);
    return num.toLocaleString() + "%";
  };

  const DaysConverter = (days) => {
    //console.log(days);
    return days + " days";
  };

  const MinsConverter = (mins) => {
    //console.log(mins);
    return mins + " mins";
  };



  useEffect(() => {
    fetchData();
  }, [isActive]);

  useEffect(() => {
    viewUrl();
  }, []);

  const viewUrl = async () => {
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_upload_promo/view_clip",
        {
          method: "POST",

          body: JSON.stringify({ doc_type: "user" }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // getData(await response.json());
      const data = await response.json();
      setLinkU(data?.promo.clip_url);
      // setSelectedSrc(data?.promo.thumbnail_url);
      //console.log(data);
    } catch (error) {
      //console.log(error);
    }

    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_upload_promo/view_clip",
        {
          method: "POST",

          body: JSON.stringify({ doc_type: "promo" }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // getData(await response.json());
      const data = await response.json();
      setLink(data?.promo.clip_url);
      // setSelectedSrc(data?.promo.thumbnail_url);
      //console.log(data);
    } catch (error) {
      //console.log(error);
    }

    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_upload_promo/view_clip",
        {
          method: "POST",

          body: JSON.stringify({ doc_type: "rider" }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // getData(await response.json());
      const data = await response.json();
      setLinkA(data?.promo.clip_url);
      // setSelectedSrc(data?.promo.thumbnail_url);
      //console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };
  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_settings/sdl_settings",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // getData(await response.json());
      const resultM = await response.json();
      setResult(resultM);
      setIsLoaded(true);
      //console.log(isLoaded);
      console.log(resultM);
      setData(resultM.stats);
     
  
      //console.log(data);
    } catch (error) {
      //console.log(error);
    }
  };



  if (!isLoaded) {
    return (
      <h1 className="loading-pages">
        <ClipLoader color={"#1FAA08"} size={100} />
      </h1>
    );
  } else if (result.msg === "Success") {
    return (
      <>
        <h3 className="settings-data-title">Meter Rates</h3>
        <div className="delivery-settings-medium2">
          <div className="delivery-vehicle-medium2">

            <div className="percentage-rows">
              <div className="previous-flat-rates">
                <div className="distance1">
                  <h2 className="distance1-title">Bike</h2>
                  <label className="settings-detail-title2">
                    Previous Meter rates
                  </label>
                  <form>
                    <div className="settings-input1" type="number">
                      {" "}
                      {Converter(data?.sdl_km_rate?.bike)}{" "}
                    </div>
                  </form>
                </div>
                <div className="distance1">
                  <label className="settings-detail-title2">
                    New Meter rates
                  </label>
                  <form>
                    <input
                      className="settings-input5"
                      type="number"
                      value={bike_km_rate}
                      onChange={(e) => setBikeKmRate(e.target.value)}
                    />
                  </form>
                  <div>
                    <button
                      className="settings-activate5"
                      disabled={disable5}
                      onClick={() =>
                        updateScheduleKmRate(
                          token,
                          "bike",
                          bike_km_rate,
                          fetchData,
                          setDisable5
                        )
                      }
                    >
                      {disable5 ? (
                        <ClipLoader
                          color={"black"}
                          loading={disable5}
                          size={15}
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </div>
              </div>

              <div className="previous-flat-rates">
                <div className="distance1">
                  <h2 className="distance1-title">Car</h2>
                  <label className="settings-detail-title2">
                    Previous Meter rates
                  </label>
                  <form>
                    <div className="settings-input1" type="number">
                      {" "}
                      {Converter(data?.sdl_km_rate?.car)}{" "}
                    </div>
                  </form>
                </div>
                <div className="distance1">
                  <label className="settings-detail-title2">
                    New Meter rates
                  </label>
                  <form>
                    <input
                      className="settings-input5"
                      type="number"
                      value={car_km_rate}
                      onChange={(e) => setCarKmRate(e.target.value)}
                    />
                  </form>
                  <div>
                    <button
                      className="settings-activate5"
                      disabled={disable6}
                      onClick={() =>
                        updateScheduleKmRate(
                          token,
                          "car",
                          car_km_rate,
                          fetchData,
                          setDisable6
                        )
                      }
                    >
                      {disable6 ? (
                        <ClipLoader
                          color={"black"}
                          loading={disable6}
                          size={15}
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </div>
              </div>

            </div>
            <div className="percentage-rows">
              <div className="previous-flat-rates">
                <div className="distance1">
                  <h2 className="distance1-title">Van</h2>
                  <label className="settings-detail-title2">
                    Previous Meter rates
                  </label>
                  <form>
                    <div className="settings-input1" type="number">
                      {" "}
                      {Converter(data?.sdl_km_rate?.van)}{" "}
                    </div>
                  </form>
                </div>
                <div className="distance1">
                  <label className="settings-detail-title2">
                    New Meter rates
                  </label>
                  <form>
                    <input
                      className="settings-input5"
                      type="number"
                      value={van_km_rate}
                      onChange={(e) => setVanKmRate(e.target.value)}
                    />
                  </form>
                  <div>
                    <button
                      className="settings-activate5"
                      disabled={disable7}
                      onClick={() =>
                        updateScheduleKmRate(
                          token,
                          "van",
                          van_km_rate,
                          fetchData,
                          setDisable7
                        )
                      }
                    >
                      {disable7 ? (
                        <ClipLoader
                          color={"black"}
                          loading={disable7}
                          size={15}
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </div>
              </div>

              <div className="previous-flat-rates">
                <div className="distance1">
                  <h2 className="distance1-title">Truck</h2>
                  <label className="settings-detail-title2">
                    Previous Meter rates
                  </label>
                  <form>
                    <div className="settings-input1" type="number">
                      {" "}
                      {Converter(data?.sdl_km_rate?.truck)}{" "}
                    </div>
                  </form>
                </div>
                <div className="distance1">
                  <label className="settings-detail-title2">
                    New Meter rates
                  </label>
                  <form>
                    <input
                      className="settings-input5"
                      type="number"
                      value={truck_km_rate}
                      onChange={(e) => setTruckKmRate(e.target.value)}
                    />
                  </form>
                  <div>
                    <button
                      className="settings-activate5"
                      disabled={disable8}
                      onClick={() =>
                        updateScheduleKmRate(
                          token,
                          "truck",
                          truck_km_rate,
                          fetchData,
                          setDisable8
                        )
                      }
                    >
                      {disable8 ? (
                        <ClipLoader
                          color={"black"}
                          loading={disable8}
                          size={15}
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3 className="settings-data-title">Time Rate</h3>
        <div className="delivery-settings-medium2">
          <div className="delivery-vehicle-medium2">

            <div className="percentage-rows">
              <div className="previous-flat-rates">
                <div className="distance1">
                  <h2 className="distance1-title">Bike</h2>
                  <label className="settings-detail-title2">
                    Previous Time rates
                  </label>
                  <form>
                    <div className="settings-input1" type="number">
                      {" "}
                      {Converter(data?.sdl_time_rate?.bike)}{" "}
                    </div>
                  </form>
                </div>
                <div className="distance1">
                  <label className="settings-detail-title2">
                    New Time rates
                  </label>
                  <form>
                    <input
                      className="settings-input5"
                      disabled={disable9}
                      type="number"
                      value={bike_time_rate}
                      onChange={(e) => setBikeTimeRate(e.target.value)}
                    />
                  </form>
                  <div>
                    <button
                      className="settings-activate5"
                      onClick={() =>
                        updateScheduleTimeRate(
                          token,
                          "bike",
                          bike_time_rate,
                          fetchData,
                          setDisable9
                        )
                      }
                    >
                      {disable9 ? (
                        <ClipLoader
                          color={"black"}
                          loading={disable9}
                          size={15}
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </div>
              </div>

              <div className="previous-flat-rates">
                <div className="distance1">
                  <h2 className="distance1-title">Car</h2>
                  <label className="settings-detail-title2">
                    Previous Time rates
                  </label>
                  <form>
                    <div className="settings-input1" type="number">
                      {" "}
                      {Converter(data?.sdl_time_rate?.car)}{" "}
                    </div>
                  </form>
                </div>
                <div className="distance1">
                  <label className="settings-detail-title2">
                    New Time rates
                  </label>
                  <form>
                    <input
                      className="settings-input5"
                      type="number"
                      value={car_time_rate}
                      onChange={(e) => setCarTimeRate(e.target.value)}
                    />
                  </form>
                  <div>
                    <button
                      className="settings-activate5"
                      disabled={disable10}
                      onClick={() =>
                        updateScheduleTimeRate(
                          token,
                          "car",
                          car_time_rate,
                          fetchData,
                          setDisable10
                        )
                      }
                    >
                      {disable10 ? (
                        <ClipLoader
                          color={"black"}
                          loading={disable10}
                          size={15}
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </div>
              </div>

            </div>
            <div className="percentage-rows">
              <div className="previous-flat-rates">
                <div className="distance1">
                  <h2 className="distance1-title">Van</h2>
                  <label className="settings-detail-title2">
                    Previous Time rates
                  </label>
                  <form>
                    <div className="settings-input1" type="number">
                      {" "}
                      {Converter(data?.sdl_time_rate?.van)}{" "}
                    </div>
                  </form>
                </div>
                <div className="distance1">
                  <label className="settings-detail-title2">
                    New Time rates
                  </label>
                  <form>
                    <input
                      className="settings-input5"
                      type="number"
                      value={van_time_rate}
                      onChange={(e) => setVanTimeRate(e.target.value)}
                    />
                  </form>
                  <div>
                    <button
                      className="settings-activate5"
                      disabled={disable11}
                      onClick={() =>
                        updateScheduleTimeRate(
                          token,
                          "van",
                          van_time_rate,
                          fetchData,
                          setDisable11
                        )
                      }
                    >
                      {disable11 ? (
                        <ClipLoader
                          color={"black"}
                          loading={disable11}
                          size={15}
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </div>
              </div>

              <div className="previous-flat-rates">
                <div className="distance1">
                  <h2 className="distance1-title">Truck</h2>
                  <label className="settings-detail-title2">
                    Previous Time rates
                  </label>
                  <form>
                    <div className="settings-input1" type="number">
                      {" "}
                      {Converter(data?.sdl_time_rate?.truck)}{" "}
                    </div>
                  </form>
                </div>
                <div className="distance1">
                  <label className="settings-detail-title2">
                    New Time rates
                  </label>
                  <form>
                    <input
                      className="settings-input5"
                      type="number"
                      value={truck_time_rate}
                      onChange={(e) => setTrucktimeRate(e.target.value)}
                    />
                  </form>
                  <div>
                    <button
                      className="settings-activate5"
                      disabled={disable12}
                      onClick={() =>
                        updateScheduleTimeRate(
                          token,
                          "truck",
                          truck_time_rate,
                          fetchData,
                          setDisable12
                        )
                      }
                    >
                      {disable12 ? (
                        <ClipLoader
                          color={"black"}
                          loading={disable12}
                          size={15}
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3 className="settings-data-title">Base Fare</h3>
        <div className="delivery-settings-medium2">
          <div className="delivery-vehicle-medium2">

            <div className="percentage-rows">
              <div className="previous-flat-rates">
                <div className="distance1">
                  <h2 className="distance1-title">Bike</h2>
                  <label className="settings-detail-title2">
                    Previous Base Fare
                  </label>
                  <form>
                    <div className="settings-input1" type="number">
                      {" "}
                      {Converter(data?.sdl_base_fare?.bike)}{" "}
                    </div>
                  </form>
                </div>
                <div className="distance1">
                  <label className="settings-detail-title2">
                    New Base Fare
                  </label>
                  <form>
                    <input
                      className="settings-input5"
                      type="number"
                      value={bike_base_fare}
                      onChange={(e) => setBikeBaseFare(e.target.value)}
                    />
                  </form>
                  <div>
                    <button
                      className="settings-activate5"
                      disabled={disable13}
                      onClick={() =>
                        updateScheduleBaseFare(
                          token,
                          "bike",
                          bike_base_fare,
                          fetchData,
                          setDisable13
                        )
                      }
                    >
                      {disable13 ? (
                        <ClipLoader
                          color={"black"}
                          loading={disable13}
                          size={15}
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </div>
              </div>

              <div className="previous-flat-rates">
                <div className="distance1">
                  <h2 className="distance1-title">Car</h2>
                  <label className="settings-detail-title2">
                    Previous Base Fare
                  </label>
                  <form>
                    <div className="settings-input1" type="number">
                      {" "}
                      {Converter(data?.sdl_base_fare?.car)}{" "}
                    </div>
                  </form>
                </div>
                <div className="distance1">
                  <label className="settings-detail-title2">
                    New Base Fare
                  </label>
                  <form>
                    <input
                      className="settings-input5"
                      type="number"
                      value={car_base_fare}
                      onChange={(e) => setCarBaseFare(e.target.value)}
                    />
                  </form>
                  <div>
                    <button
                      className="settings-activate5"
                      disabled={disable14}
                      onClick={() =>
                        updateScheduleBaseFare(
                          token,
                          "car",
                          car_base_fare,
                          fetchData,
                          setDisable14
                        )
                      }
                    >
                      {disable14 ? (
                        <ClipLoader
                          color={"black"}
                          loading={disable14}
                          size={15}
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </div>
              </div>

            </div>
            <div className="percentage-rows">
              <div className="previous-flat-rates">
                <div className="distance1">
                  <h2 className="distance1-title">Van</h2>
                  <label className="settings-detail-title2">
                    Previous Base Fare
                  </label>
                  <form>
                    <div className="settings-input1" type="number">
                      {" "}
                      {Converter(data?.sdl_base_fare?.van)}{" "}
                    </div>
                  </form>
                </div>
                <div className="distance1">
                  <label className="settings-detail-title2">
                    New Base Fare
                  </label>
                  <form>
                    <input
                      className="settings-input5"
                      type="number"
                      value={van_base_fare}
                      onChange={(e) => setVanBaseFare(e.target.value)}
                    />
                  </form>
                  <div>
                    <button
                      className="settings-activate5"
                      disabled={disable15}
                      onClick={() =>
                        updateScheduleBaseFare(
                          token,
                          "van",
                          van_base_fare,
                          fetchData,
                          setDisable15
                        )
                      }
                    >
                      {disable15 ? (
                        <ClipLoader
                          color={"black"}
                          loading={disable15}
                          size={15}
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </div>
              </div>

              <div className="previous-flat-rates">
                <div className="distance1">
                  <h2 className="distance1-title">Truck</h2>
                  <label className="settings-detail-title2">
                    Previous Base Fare
                  </label>
                  <form>
                    <div className="settings-input1" type="number">
                      {" "}
                      {Converter(data?.sdl_base_fare?.truck)}{" "}
                    </div>
                  </form>
                </div>
                <div className="distance1">
                  <label className="settings-detail-title2">
                    New Base Fare
                  </label>
                  <form>
                    <input
                      className="settings-input5"
                      type="number"
                      value={truck_base_fare}
                      onChange={(e) => setTruckBaseFare(e.target.value)}
                    />
                  </form>
                  <div>
                    <button
                      className="settings-activate5"
                      disabled={disable16}
                      onClick={() =>
                        updateScheduleBaseFare(
                          token,
                          "truck",
                          truck_base_fare,
                          fetchData,
                          setDisable16
                        )
                      }
                    >
                      {disable16 ? (
                        <ClipLoader
                          color={"black"}
                          loading={disable16}
                          size={15}
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3 className="settings-data-title">Minimum price </h3>
        <div className="delivery-settings-medium2">
          <div className="delivery-vehicle-medium2">

            <div className="percentage-rows">
              <div className="previous-flat-rates">
                <div className="distance1">
                  <h2 className="distance1-title">Bike</h2>
                  <label className="settings-detail-title2">
                    Previous Min Price{" "}
                  </label>
                  <form>
                    <div className="settings-input1" type="number">
                      {" "}
                      {Converter(data?.sdl_min_price?.bike)}{" "}
                    </div>
                  </form>
                </div>
                <div className="distance1">
                  <label className="settings-detail-title2">
                    New Min Price{" "}
                  </label>
                  <form>
                    <input
                      className="settings-input5"
                      type="number"
                      value={bike_min_rate}
                      onChange={(e) => setBikeMinRate(e.target.value)}
                    />
                  </form>
                  <div>
                    <button
                      className="settings-activate5"
                      disabled={disable17}
                      onClick={() =>
                        updateScheduleMinPrice(
                          token,
                          "bike",
                          bike_min_rate,
                          fetchData,
                          setDisable17
                        )
                      }
                    >
                      {disable17 ? (
                        <ClipLoader
                          color={"black"}
                          loading={disable17}
                          size={15}
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </div>
              </div>

              <div className="previous-flat-rates">
                <div className="distance1">
                  <h2 className="distance1-title">Car</h2>
                  <label className="settings-detail-title2">
                    Previous Min Price{" "}
                  </label>
                  <form>
                    <div className="settings-input1" type="number">
                      {" "}
                      {Converter(data?.sdl_min_price?.car)}{" "}
                    </div>
                  </form>
                </div>
                <div className="distance1">
                  <label className="settings-detail-title2">
                    New Min Price{" "}
                  </label>
                  <form>
                    <input
                      className="settings-input5"
                      type="number"
                      value={car_min_rate}
                      onChange={(e) => setCarMinRate(e.target.value)}
                    />
                  </form>
                  <div>
                    <button
                      className="settings-activate5"
                      disabled={disable18}
                      onClick={() =>
                        updateScheduleMinPrice(
                          token,
                          "car",
                          car_min_rate,
                          fetchData,
                          setDisable18
                        )
                      }
                    >
                      {disable18 ? (
                        <ClipLoader
                          color={"black"}
                          loading={disable18}
                          size={15}
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </div>
              </div>

            </div>
            <div className="percentage-rows">
              <div className="previous-flat-rates">
                <div className="distance1">
                  <h2 className="distance1-title">Van</h2>
                  <label className="settings-detail-title2">
                    Previous Min Price{" "}
                  </label>
                  <form>
                    <div className="settings-input1" type="number">
                      {" "}
                      {Converter(data?.sdl_min_price?.van)}{" "}
                    </div>
                  </form>
                </div>
                <div className="distance1">
                  <label className="settings-detail-title2">
                    New Min Price{" "}
                  </label>
                  <form>
                    <input
                      className="settings-input5"
                      type="number"
                      value={van_min_rate}
                      onChange={(e) => setVanMinRate(e.target.value)}
                    />
                  </form>
                  <div>
                    <button
                      className="settings-activate5"
                      disabled={disable19}
                      onClick={() =>
                        updateScheduleMinPrice(
                          token,
                          "van",
                          van_min_rate,
                          fetchData,
                          setDisable19
                        )
                      }
                    >
                      {disable19 ? (
                        <ClipLoader
                          color={"black"}
                          loading={disable19}
                          size={15}
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </div>
              </div>

              <div className="previous-flat-rates">
                <div className="distance1">
                  <h2 className="distance1-title">Truck</h2>
                  <label className="settings-detail-title2">
                    Previous Min Price{" "}
                  </label>
                  <form>
                    <div className="settings-input1" type="number">
                      {" "}
                      {Converter(data?.sdl_min_price?.truck)}{" "}
                    </div>
                  </form>
                </div>
                <div className="distance1">
                  <label className="settings-detail-title2">
                    New Min Price{" "}
                  </label>
                  <form>
                    <input
                      className="settings-input5"
                      type="number"
                      value={truck_min_rate}
                      onChange={(e) => setTruckMinRate(e.target.value)}
                    />
                  </form>
                  <div>
                    <button
                      className="settings-activate5"
                      disabled={disable20}
                      onClick={() =>
                        updateScheduleMinPrice(
                          token,
                          "truck",
                          truck_min_rate,
                          fetchData,
                          setDisable20
                        )
                      }
                    >
                      {disable20 ? (
                        <ClipLoader
                          color={"black"}
                          loading={disable20}
                          size={15}
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
            
      </>


    )
  }

};

export default ScheduledSetting;
